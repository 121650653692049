import React from 'react';
import { Link } from 'react-router-dom';
import { BiCheckbox, BiCheckboxChecked } from 'react-icons/bi';
import {
  BsFillEmojiLaughingFill,
  BsFillEmojiNeutralFill,
  BsFillEmojiFrownFill,
} from 'react-icons/bs';

import dayjs from 'dayjs';
import { MdSpeakerNotes } from 'react-icons/md';
import { toast } from 'react-toastify';
import { compareDates } from '../Onboarding/waitingOnboardingColumns';
import TooltipGeneric from '../../../components/Tooltip';
import ButtonGeneric from '../../../components/Button';
import { CS_ACTIONS_KEYS, CS_NEXT_ACTIONS } from '../../../constants/cs';
import ActiviteContactProvider from '../../../providers/activiteContact';
import setStoreRedux from '../../../utils/setStore';
import { renderStatus } from '../../Demands/listDemands/demandsColumn';
import customerProvider from '../../../providers/customer';

const renderHSCell = (customer) => {
  const icons = {
    success: (
      <BsFillEmojiLaughingFill size={22} className="text-success me-1" />
    ),
    warning: (
      <BsFillEmojiNeutralFill size={22} color="orange" className="me-1" />
    ),
    danger: <BsFillEmojiFrownFill size={22} className="text-danger me-1" />,
  };

  const handleModalNotes = async () => {
    const getCustomer = await customerProvider.getById(customer.id);
    setStoreRedux('SET_CUSTOMER', {
      ...getCustomer,
    });
    setStoreRedux('SET_MODAL_STATE', {
      modalAnnotations: {
        isOpen: true,
      },
    });
  };

  let color = '';
  let icon = null;

  if (+customer.healthScore >= 70) {
    color = 'success';
  } else if (+customer.healthScore >= 50) {
    color = 'warning';
  } else {
    color = 'danger';
  }

  icon = icons[color];

  return (
    <div className="d-flex align-items-center p-0 m-0 font-weight-bold">
      {icon}
      <p className={`text-${color} p-0 m-0`}>{customer.healthScore}</p>
      {customer?.tags && (
        <TooltipGeneric title="Cliente Inadimplente" placement="right">
          <p
            className="p-0 m-0 cursor-pointer"
            style={{
              marginLeft: '2px',
            }}
          >
            🚩
          </p>
        </TooltipGeneric>
      )}
      <TooltipGeneric title="Anotações" placement="right">
        <p className="p-0 m-0">
          <MdSpeakerNotes
            className={`text-${color} ms-2 cursor-pointer`}
            size={20}
            onClick={() => handleModalNotes()}
          />
        </p>
      </TooltipGeneric>
    </div>
  );
};

const renderCustomerCell = (name, id) => {
  return (
    <Link
      className="d-flex align-items-center text-decoration-none text-dark"
      to={`/customers/${id}`}
    >
      {name}
    </Link>
  );
};

const openModalEditResponsible = (item) => {
  setStoreRedux('OPEN_MODAL');
  let currentUserBackoffice = {};
  if (item && item.ActiveContacts && item.ActiveContacts.length > 0) {
    currentUserBackoffice = {
      name: item?.ActiveContacts[0]?.User?.name || item?.csBackofficeUserName,
      id: item?.ActiveContacts[0]?.backofficeUserId || item?.csBackofficeUserId,
    };
  } else {
    currentUserBackoffice = {
      name:
        item?.customer.ActiveContacts[0].backofficeUserName ||
        item?.csBackofficeUserName,
      id:
        item?.customer.ActiveContacts[0].backofficeUserId ||
        item?.csBackofficeUserId,
    };
  }
  setStoreRedux('SET_ACTIVITE_CONTACT_STATE', {
    currentUserBackoffice,
  });
  setStoreRedux('SET_ACTIVITE_CONTACT_STATE', {
    currentCustomer: item,
    currentActiveContact: item.ActiveContacts
      ? item.ActiveContacts[0]
      : item?.customer.ActiveContacts[0],
  });
};

const buttonActiviteContactUser = (item, userName) => {
  return (
    <ButtonGeneric
      variant="dark"
      size="sm"
      style={{ fontSize: '12px' }}
      onClick={() => openModalEditResponsible(item)}
    >
      {userName}
    </ButtonGeneric>
  );
};

const generateNextActionDate = (followUps) => {
  const lastFollowUp = followUps[followUps.length - 1];
  const isAfter = dayjs().isAfter(lastFollowUp?.dueDate, 'day');

  return (
    <p
      style={{ fontSize: '12px' }}
      className={isAfter ? 'text-danger fw-bold' : ''}
    >
      {dayjs(lastFollowUp?.dueDate).format('DD/MM/YYYY')}
    </p>
  );
};

const createActiveContactDemandURL = (row) => {
  const demandParams = row.ActiveContacts.length
    ? {
        name: `Reunião Contato Ativo - ${row.customer.name}`,
        descriptionKey: 'SUCCESIVE_CONTACTS',
        customerId: row.customer.id,
        demandTypeId: '82c3ce78-abaa-4698-bc02-bf21592d0d77',
        backofficeUserId: row.ActiveContacts[0].backofficeUserId,
      }
    : {
        name: `Reunião Contato Ativo - ${row.customer.name}`,
        descriptionKey: 'FIRST_CONTACT',
        customerId: row.customer.id,
        demandTypeId: '82c3ce78-abaa-4698-bc02-bf21592d0d77',
      };

  const urlSearchParamsString = new URLSearchParams(demandParams).toString();

  return `#/demands/new?${urlSearchParamsString}`;
};

const generateSuggestedMeetingOrButton = ({ row }) => {
  const actions = row.ActiveContacts[0]?.ActiveContactsActions[0];
  const suggestedMeeting = row.suggestedMeeting.value;
  return suggestedMeeting && actions ? (
    dayjs(suggestedMeeting).format('DD/MM/YYYY')
  ) : (
    <ButtonGeneric
      type="button"
      className="text-white"
      size="sm"
      style={{ fontSize: '12px' }}
      onClick={() => {
        window.location.href = `${createActiveContactDemandURL(row)}`;
      }}
    >
      Sugerir Reunião
    </ButtonGeneric>
  );
};

const generateNextMeeting = (nextActiveContactDate) => (
  <p
    style={{ fontSize: '12px' }}
    className={
      dayjs().isAfter(nextActiveContactDate, 'day') ? 'text-danger fw-bold' : ''
    }
  >
    {dayjs(nextActiveContactDate).format('DD/MM/YYYY')}
  </p>
);

const sortingContactActionDesc = (actions) => {
  return actions?.sort((a, b) => {
    const aValue = a?.createdAt;
    const bValue = b?.createdAt;
    return new Date(bValue) - new Date(aValue);
  });
};

const generateLastAction = ({ row, setShowModal }) => {
  const suggestedMeeting = row.suggestedMeeting.value;
  const actions = sortingContactActionDesc(
    row.ActiveContacts[0]?.ActiveContactsActions,
  );

  const currentAction =
    actions?.[0]?.currentAction || 'Criar Demanda de Reunião';
  const indexOFAction = 0;
  let nextActionDate = dayjs(actions?.[indexOFAction]?.createdAt).add(
    7,
    'days',
  );
  if (!actions?.[indexOFAction]?.createdAt) {
    nextActionDate = undefined;
  }

  const isAfter = dayjs().isAfter(dayjs(nextActionDate).add(7, 'days'), 'day');
  const checkbox =
    currentAction !== 'Finished' ? (
      <BiCheckbox
        size={22}
        onClick={() => {
          setShowModal(true);
          if (CS_ACTIONS_KEYS.has(currentAction)) {
            setStoreRedux('SET_ACTIVITE_CONTACT_STATE', {
              currentAction,
              currentCustomer: row?.customer?.id,
              currentActiveContact: row?.ActiveContacts[0],
              currentDescription: `Isso fará a próxima atividade 
            '${
              CS_NEXT_ACTIONS[CS_NEXT_ACTIONS[currentAction]] ||
              'Criar Demanda de Reunião'
            }
            ' seja marcada para ${dayjs(dayjs().add(7, 'days')).format(
              'DD/MM/YYYY',
            )}. 7 dias à frente da data de hoje`,
            });
          } else {
            setStoreRedux('SET_ACTIVITE_CONTACT_STATE', {
              currentAction,
              currentDescription: 'Isso irá encerrar as ações desse cliente',
              currentCustomer: row?.customer?.id,
              currentActiveContact: row?.ActiveContacts[0],
            });
          }
        }}
      />
    ) : (
      <BiCheckboxChecked
        size={22}
        onClick={() => toast.error('Item já marcado')}
      />
    );

  return (
    <p
      style={{ fontSize: '12px' }}
      className={
        isAfter && nextActionDate && suggestedMeeting
          ? 'text-danger fw-bold'
          : ''
      }
    >
      {nextActionDate && suggestedMeeting
        ? dayjs(nextActionDate).format('DD/MM/YYYY')
        : 'Aguardando...'}
      {nextActionDate && suggestedMeeting && checkbox}
    </p>
  );
};

const linkLastActionDate = (engagement, item) => {
  return (
    <Link
      title={
        item?.annotations?.reduce(
          (prev, curr) => (curr?.type === 'cs' ? curr.content : prev),
          '',
        ) || ''
      }
      className="d-flex align-items-center text-decoration-none text-dark"
      to={`/customers/${item.id}?ANNOTATION_TYPE=cs`}
      style={{ fontSize: '12px' }}
    >
      {engagement?.lastActivity
        ? dayjs(engagement.lastActivity).format('DD/MM/YYYY')
        : 'Nunca'}
    </Link>
  );
};

const generateActionsButton = (followUps, fetch, filters) => {
  const lastFollowUp = followUps[followUps.length - 1];

  const handleConfirmFollowUp = async () => {
    await ActiviteContactProvider.confirmFollowUp(lastFollowUp);
    await fetch(filters);
  };

  return (
    <ButtonGeneric
      variant="success"
      className="text-white"
      size="sm"
      style={{ fontSize: '12px' }}
      type="button"
      onClick={handleConfirmFollowUp}
    >
      Confirmar FUP
    </ButtonGeneric>
  );
};

const commonColumns = [
  {
    name: 'HS',
    selector: (row) => renderHSCell(row.customer),
    sortFunction: (a, b) => a.customer.healthScore - b.customer.healthScore,
    sortable: true,
    maxWidth: '12%',
    center: true,
  },
  {
    name: 'Clientes',
    cell: (row) => {
      return renderCustomerCell(
        row.customer.name,
        row.customer.customerId || row.customer.id,
      );
    },
    sortFunction: (a, b) => {
      const aValue = a.customer.name;
      const bValue = b.customer.name;
      return aValue.localeCompare(bValue);
    },
    sortable: true,
  },
  {
    name: 'Responsável',
    selector: (row) =>
      buttonActiviteContactUser(row.backoffice.item, row.backoffice.name),
    sortFunction: (a, b) => {
      const aValue = a.backoffice.name || '';
      const bValue = b.backoffice.name || '';

      return aValue.localeCompare(bValue);
    },
    sortable: true,
    maxWidth: '13%',
    center: true,
  },
];

const columnsMetricsActiviteContact = [
  {
    name: 'Contato ativo nos',
    selector: (row) => row.activeContactUs,
  },
  {
    name: 'Aguardando Contato',
    selector: (row) => row.waitingContact,
    sortable: true,
  },
  {
    name: 'Reunião realizada',
    selector: (row) => row.meetingHeld,
    sortable: true,
  },
  {
    name: 'Percentual de reuniões realizadas',
    selector: (row) => row.meetingHeldPercent,
    sortable: true,
  },
];

const columnsStatusContact = [
  ...commonColumns,
  {
    name: 'Data da próxima reunião',
    selector: (row) => {
      return generateNextMeeting(row.nextMeeting);
    },
    sortFunction: (a, b) => {
      const aValue = a.nextMeeting || '';
      const bValue = b.nextMeeting || '';
      return compareDates(aValue, bValue);
    },
    sortable: true,
    maxWidth: '8%',
    center: true,
  },
  {
    name: 'Data de reunião sugerida',
    selector: (row) => {
      return generateSuggestedMeetingOrButton({ row });
    },

    sortFunction: (a, b) => {
      const aValue = a.suggestedMeeting.value || '01/01/2000';
      const bValue = b.suggestedMeeting.value || '01/01/2000';
      return compareDates(aValue, bValue);
    },
    sortable: true,
    maxWidth: '13%',
    center: true,
  },
  {
    name: 'Próxima ação',
    cell: (row) => row.nextAction,
    sortable: true,
    maxWidth: '15%',
  },
  {
    name: 'Data da próxima ação',
    selector: (row) => {
      return generateLastAction({
        row,
        setShowModal: row.lastAction.setShowModal,
      });
    },
    sortFunction: (a, b) => {
      const aValue = a.lastAction.nextActionDate || '01/01/2000';
      const bValue = b.lastAction.nextActionDate || '01/01/2000';

      return compareDates(aValue, bValue);
    },
    sortable: true,
    maxWidth: '12%',
    center: true,
  },
  {
    name: 'Data da ultima ação',
    selector: (row) =>
      linkLastActionDate(
        row.lastActionDate.engagement,
        row.lastActionDate.item,
        row,
      ),
    sortFunction: (a, b) => {
      const aValue = a.lastActionDate.engagement.lastActivity;
      const bValue = b.lastActionDate.engagement.lastActivity;
      return compareDates(aValue, bValue);
    },
    sortable: true,
    maxWidth: '8%',
    center: true,
  },
];

const columnsValueDemand = (filters) => [
  ...commonColumns,
  {
    name: 'Demanda',
    cell: (row) => (
      <Link
        className="d-flex align-items-center text-decoration-none text-dark"
        to={`?demandId=${row.demand.id}`}
      >
        {row.demand.name}
      </Link>
    ),
    sortable: true,
  },
  {
    name: 'Status da demanda',
    cell: (row) => {
      if (row.demand.finishedCount >= 1) {
        return <p className="text-primary fw-bold p-0 m-0">Meta Finalizada</p>;
      }
      return renderStatus(row.demand.statusKey);
    },
    sortable: true,
    center: true,
    maxWidth: '10%',
  },
  {
    name: 'Próxima ação',
    cell: (row) => row.nextAction,
    sortable: true,
  },
  {
    name: 'Data da próxima ação',
    selector: (row) => generateNextActionDate(row.nextActionDate),
    sortFunction: (a, b) => {
      const aValue = a.nextActionDate[a.nextActionDate.length - 1].dueDate;
      const bValue = b.nextActionDate[b.nextActionDate.length - 1].dueDate;
      return compareDates(aValue, bValue);
    },
    sortable: true,
    maxWidth: '8%',
    center: true,
  },
  {
    name: 'Ações',
    maxWidth: '11%',
    center: true,
    selector: (row) =>
      generateActionsButton(row.actions.FollowUps, row.actions.fetch, filters),
  },
];

export {
  columnsMetricsActiviteContact,
  columnsStatusContact,
  columnsValueDemand,
};
