import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ButtonGeneric from '../../../components/Button';

export default function HeaderCustomer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const customer = useSelector((state) => state.customer);

  const openModal = (type) => {
    dispatch({
      type: 'SET_MODAL_STATE',
      payload: {
        [type]: {
          isOpen: true,
        },
      },
    });
  };

  return (
    <div
      className="border-bottom pb-3"
      style={{
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: '1rem',
      }}
    >
      <ButtonGeneric
        variant="warning"
        type="button"
        icon={null}
        onClick={() => openModal('modalRegisteNewSale')}
        className="text-white"
        id="registerNewSaleButton"
      >
        Registrar Nova Venda
      </ButtonGeneric>

      <ButtonGeneric
        type="button"
        icon={null}
        onClick={() => openModal('ModalUpdateFormSale')}
        style={{
          backgroundColor: 'coral',
        }}
        className="text-white border-0"
      >
        Atualizar formulário de venda
      </ButtonGeneric>

      <ButtonGeneric
        variant="dark"
        type="button"
        icon={null}
        onClick={() => openModal('modalSendEmailContract')}
        style={{
          backgroundColor: 'brown',
        }}
        className="text-white border-0"
      >
        E-mail Contrato
      </ButtonGeneric>

      <ButtonGeneric
        variant="dark"
        type="button"
        icon={null}
        onClick={() =>
          dispatch({
            type: 'STATE_MODAL_DEMAND',
            payload: { modalType: 'Tags' },
          })
        }
        style={{
          backgroundColor: 'purple',
        }}
        className="text-white border-0"
        id="tagsModalButton"
      >
        Tags
      </ButtonGeneric>

      <ButtonGeneric
        variant="dark"
        type="button"
        icon={null}
        onClick={() => openModal('modalAnnotations')}
        style={{
          backgroundColor: '#0585ed',
        }}
        className="text-white border-0"
      >
        Anotações
      </ButtonGeneric>

      <ButtonGeneric
        variant="dark"
        type="button"
        icon={null}
        onClick={() =>
          window.open(`#/journeys/demands-panel?customerId=${customer?.id}`)
        }
        style={{
          backgroundColor: 'rosybrown',
        }}
        className="text-white border-0"
      >
        Jornadas
      </ButtonGeneric>

      <ButtonGeneric
        variant="dark"
        type="button"
        icon={null}
        onClick={() =>
          navigate(`/business/demands-panel?customerId=${customer?.id}`)
        }
        style={{
          backgroundColor: '#ed9405',
        }}
        className="text-white border-0"
        id="demandsNavigationButton"
      >
        Demandas
      </ButtonGeneric>

      <ButtonGeneric
        variant="dark"
        type="button"
        icon={null}
        onClick={() => navigate('edit')}
        style={{
          backgroundColor: '#1ab394',
        }}
        className="text-white border-0"
        id="editCustomerButton"
      >
        Editar
      </ButtonGeneric>
    </div>
  );
}
