import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import dayjs from 'dayjs';
import ModalForm from '../../../components/Modals/ModalForm';
import schemaGeneric from '../../../validators/schemas/schemaGeneric';
import InputGeneric from '../../../components/Inputs/Input_generic';
import InputSelect from '../../../components/Inputs/Input_Select';
import CONSTANTE_CUSTOMER from '../../../constants/customer';
import businessProvider from '../../../providers/business';
import InputMask from '../../../components/Inputs/Input_Mask';
import InputCalendar from '../../../components/Inputs/Input_Calendar';
import customerProvider from '../../../providers/customer';
import customersActions from '../../../store/actions/customersActions';

export default function ModalCreateBusiness() {
  const modal = useSelector((state) => state.modal);
  const customer = useSelector((state) => state.customer);
  const backofficeList = useSelector((state) => state.backofficeList);

  const dispatch = useDispatch();

  const resetModal = () => {
    dispatch({ type: 'RESET_MODAL_STATE' });
  };

  const onSubmit = async (data) => {
    if (
      data.backofficeUserId ||
      (data.nextActiveContactDate &&
        dayjs(data.nextActiveContactDate)
          .startOf('day')
          .diff(dayjs(customer?.ActiveContacts[0]?.contactDate).startOf('day')))
    ) {
      const activeContactInfos = {
        backofficeUserId: data.backofficeUserId,
        nextActiveContactDate:
          dayjs(data.nextActiveContactDate)
            .startOf('day')
            .isBefore(
              dayjs(customer?.ActiveContacts[0]?.createdAt).startOf('day'),
            ) ||
          dayjs(data.nextActiveContactDate)
            .startOf('day')
            .isSame(
              dayjs(customer?.ActiveContacts[0]?.createdAt).startOf('day'),
            )
            ? dayjs(customer?.ActiveContacts[0]?.contactDate)
            : dayjs(data.nextActiveContactDate),
        contactActiveId:
          modal?.modalCreateBusiness?.business?.ActiveContacts[0]?.id,
        constactActiveCustomerId: customer?.ActiveContacts[0]?.id,
      };
      await customerProvider.updateActiviteContact(
        customer.id,
        activeContactInfos,
      );
    }

    const reqData = { ...data };
    delete reqData.backofficeUserId;
    delete reqData.nextActiveContactDate;
    const request = {
      ...reqData,
      customerId: customer.id,
    };
    !modal?.modalCreateBusiness?.edit
      ? await businessProvider.create(request)
      : await businessProvider.update(
          modal?.modalCreateBusiness?.business?.id,
          request,
        );
    customersActions.fetchById(customer.id);
    resetModal();
  };

  return (
    <ModalForm
      open={modal?.modalCreateBusiness?.isOpen}
      close={resetModal}
      schema={schemaGeneric}
      overflowScroll
      onSubmit={onSubmit}
      style={{
        width: '60%',
      }}
    >
      <h3 className="text-center">
        {modal?.modalCreateBusiness?.business
          ? 'Editar Negócio'
          : 'Adicionar negocio'}
      </h3>
      <InputGeneric
        name="name"
        label="Nome"
        placeholder="Digite o nome do negócio"
        defaultValue={modal?.modalCreateBusiness?.business?.name}
      />
      <InputSelect
        options={CONSTANTE_CUSTOMER.SOCIETY_TYPES}
        name="typeCompany"
        label="Tipo de sociedade"
        placeholder="Selecione o tipo de sociedade do negócio"
        defaultValue={modal?.modalCreateBusiness?.business?.typeCompany}
      />
      <Row>
        <Col>
          <InputSelect
            options={[
              {
                value: 'PF',
                label: 'Pessoa Física',
              },
              {
                value: 'PJ',
                label: 'Pessoa Jurídica',
              },
            ]}
            name="personType"
            label="Tipo de pessoa"
            placeholder="Selecione o tipo de pessoa do negócio"
            defaultValue={modal?.modalCreateBusiness?.business?.personType}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <InputSelect
            options={CONSTANTE_CUSTOMER.CUSTOMER_TYPES.OPTIONS}
            name="businessType"
            label="Tipo do negócio"
            placeholder="Digite o tipo do negócio"
            defaultValue={modal?.modalCreateBusiness?.business?.businessType}
          />
        </Col>
        <Col>
          <InputGeneric
            name="addressState"
            label="Estado"
            placeholder="Digite o estado do negócio"
            defaultValue={modal?.modalCreateBusiness?.business?.addressState}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <InputGeneric
            name="addressCity"
            label="Cidade"
            placeholder="Digite a cidade do negócio"
            defaultValue={modal?.modalCreateBusiness?.business?.addressCity}
          />
        </Col>
        <Col>
          <InputGeneric
            name="addressDistrict"
            label="Bairro"
            placeholder="Digite o bairro do negócio"
            defaultValue={modal?.modalCreateBusiness?.business?.addressDistrict}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <InputGeneric
            name="addressNumber"
            label="Número"
            placeholder="Digite o número do negócio"
            defaultValue={modal?.modalCreateBusiness?.business?.addressNumber}
          />
        </Col>
        <Col>
          <InputGeneric
            name="addressComplement"
            label="Complemento"
            placeholder="Digite o complemento do negócio"
            defaultValue={
              modal?.modalCreateBusiness?.business?.addressComplement
            }
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <InputGeneric
            name="address"
            label="Logradouro"
            placeholder="Digite a logradouro do negócio"
            defaultValue={modal?.modalCreateBusiness?.business?.address}
          />
        </Col>
        <Col>
          <InputMask
            name="cep"
            type="cep"
            label="Cep"
            placeholder="Digite o estado do negócio"
            defaultValue={modal?.modalCreateBusiness?.business?.cep}
          />
        </Col>
      </Row>
      {customer?.data?.contactFor !== 'Customers' && (
        <Row>
          <Col>
            <InputSelect
              name="backofficeUserId"
              label="Responsável pelo contato"
              placeholder="Selecione o responsável"
              options={backofficeList?.rows?.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
              defaultValue={[
                modal?.modalCreateBusiness?.business?.ActiveContacts[0]?.User
                  ?.id,
              ]}
            />
          </Col>
          <Col>
            <InputCalendar
              name="nextActiveContactDate"
              label="Data do próximo contato"
              defaultValue={[
                Array.isArray(customer?.ActiveContacts) &&
                  customer?.ActiveContacts[0]?.contactDate,
              ]}
            />
          </Col>
        </Row>
      )}
    </ModalForm>
  );
}
