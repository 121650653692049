const CONSTANTE_CUSTOMER = {
  SOCIETY_TYPES: [
    { label: 'Sociedade Limitada', value: 'Sociedade Limitada' },
    { label: 'Sociedade Anônima', value: 'Sociedade Anônima' },
    { label: 'Sociedade Individual', value: 'Sociedade Individual' },
    {
      label: 'Sociedade Individual de Responsabilidade Limitada',
      value: 'Sociedade Individual de Responsabilidade Limitada',
    },
    {
      label: 'Sociedade de Propósito Específico',
      value: 'Sociedade de Propósito Específico',
    },
    {
      label: 'Microempreendedor Individual',
      value: 'Microempreendedor Individual',
    },
    { label: 'Empresário Individual', value: 'Empresário Individual' },
    {
      label: 'Empresa Individual de Responsabilidade Limitada',
      value: 'Empresa Individual de Responsabilidade Limitada',
    },
    {
      label: 'Sociedade em Nome Coletivo',
      value: 'Sociedade em Nome Coletivo',
    },
    {
      label: 'Sociedade em Comandita Simples',
      value: 'Sociedade em Comandita Simples',
    },
    {
      label: 'Sociedade em Comandita por Ações',
      value: 'Sociedade em Comandita por Ações',
    },
  ],
  CUSTOMER_TYPES: {
    AGRO: 'agro',
    FINTECH: 'fintech',
    MARKETPLACE: 'marketplace',
    SAUDE: 'saude',
    TECH: 'tech',
    LIST: ['agro', 'marketplace', 'saude', 'tech', 'fintech'],
    OPTIONS: [
      { label: 'Agro', value: 'agro' },
      { label: 'Fintech', value: 'fintech' },
      { label: 'Marketplace', value: 'marketplace' },
      { label: 'Saúde', value: 'saude' },
      { label: 'Tech', value: 'tech' },
      { label: 'Todos', value: null },
    ],
  },
  CHURN_REASONS: [
    { label: 'Rescisão Unilateral NDM', value: 0, isNDM: true },
    { label: 'Rescisão por Inadimplência', value: 1, isNDM: true },
    { label: 'Descontinuidade do Negócio', value: 2, isNDM: false },
    { label: 'Corte de Custos', value: 3, isNDM: false },
    { label: 'Baixa Demanda', value: 4, isNDM: false },
    { label: 'Insatisfação do Cliente', value: 5, isNDM: false },
    { label: 'Apoio de Outro Jurídico', value: 6, isNDM: false },
    { label: 'Não retornou motivação', value: 7, isNDM: false },
  ],
  CONTRACT_TYPES: [
    { label: 'Assessoria Jurídica', value: 'legalAdvice' },
    { label: 'Demanda Específica', value: 'specificDemand' },
  ],
  ANNOTATION_TYPES: {
    general: {
      key: 'general',
      name: 'Geral',
      color: '#54a7bb',
    },
    sales: {
      key: 'sales',
      name: 'Vendas',
      color: '#219ebc',
    },
    onboarding: {
      key: 'onboarding',
      name: 'Onboarding',
      color: '#03486a',
    },
    ongoing: {
      key: 'ongoing',
      name: 'Atividades de CS',
      color: '#022e44',
    },
    emails: {
      key: 'emails',
      name: 'Emails',
      color: '#001b26',
    },
    cs: {
      key: 'cs',
      name: 'CS',
      color: '#001017',
    },
    finance: {
      key: 'finance',
      name: 'Financeiro',
      color: '#808080',
    },
  },
};

export const CUSTOMER_SECTORS = [
  'Vendas',
  'RH',
  'Marketing',
  'Financeiro',
  'Suporte ao Cliente',
  'Sucesso do Cliente',
  'Jurídico',
  'Fornecedores e Parceiros',
  'Uso da Plataforma',
];

export default CONSTANTE_CUSTOMER;
