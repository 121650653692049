const optionsInputFilterPartnersList = () => [
  {
    value: 'cnpj',
    label: 'CNPJ',
    type: 'input',
    operator: 'iLike',
  },
  {
    value: 'email',
    label: 'Email',
    type: 'input',
    operator: 'iLike',
  },
  {
    value: 'cpf',
    label: 'CPF',
    type: 'input',
    operator: 'iLike',
  },
];

export default optionsInputFilterPartnersList;
