import dayjs from 'dayjs';
import * as yup from 'yup';

export function isCPFValid(cpf) {
  // eslint-disable-next-line no-param-reassign
  cpf = cpf.replace(/\D/g, '');

  if (cpf.length !== 11) {
    return false;
  }

  if (/^(\d)\1{10}$/.test(cpf)) {
    return false;
  }

  let sum = 0;
  for (let i = 0; i < 9; i += 1) {
    sum += parseInt(cpf.charAt(i), 10) * (10 - i);
  }
  let remainder = 11 - (sum % 11);
  const firstDigit = remainder >= 10 ? 0 : remainder;

  sum = 0;
  for (let i = 0; i < 10; i += 1) {
    sum += parseInt(cpf.charAt(i), 10) * (11 - i);
  }
  remainder = 11 - (sum % 11);
  const secondDigit = remainder >= 10 ? 0 : remainder;

  if (
    parseInt(cpf.charAt(9), 10) !== firstDigit ||
    parseInt(cpf.charAt(10), 10) !== secondDigit
  ) {
    return false;
  }

  return true;
}

const schemaCreateCustomerPF = yup.object().shape({
  name: yup.string().required('Campo obrigatório'),
  contractExpiration: yup
    .string()
    .required('Campo obrigatório')
    .test('valid-date', 'Data inválida', (value) => {
      return dayjs(value, { strict: true }).isValid();
    }),
  contractType: yup.string().required('Campo obrigatório'),
  saleDate: yup
    .string()
    .required('Campo obrigatório')
    .test('valid-date', 'Data inválida', (value) => {
      return dayjs(value, { strict: true }).isValid();
    }),
  cpf: yup
    .string()
    .required('Campo obrigatório')
    .matches(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/, 'CPF inválido')
    .test('valid-cpf', 'CPF inválido', (value) => {
      return isCPFValid(value);
    }),
  isInternacional: yup.boolean(),
  'cep/ZIPCODE': yup
    .string()
    .required('Campo obrigatório')
    .when('isInternacional', {
      is: false,
      then: (schema) =>
        schema
          .required('Campo obrigatório')
          .matches(/^\d{5}-\d{3}$|^\d{5}$/, 'CEP/ZIPCODE inválido'),
      otherwise: () => yup.string().required('Campo obrigatório'),
    }),
  address: yup.string().required('Campo obrigatório'),
  addressNumber: yup.string().required('Campo obrigatório'),
  addressDistrict: yup.string().required('Campo obrigatório'),
  addressCity: yup.string().required('Campo obrigatório'),
  addressState: yup.string().required('Campo obrigatório'),
  email: yup.string().email('E-mail inválido').required('Campo obrigatório'),
  phone: yup.string().required('Campo obrigatório'),
  instalmentValue: yup.number().required('Campo obrigatório'),
  numberOfInstallments: yup.number().required('Campo obrigatório'),
  dateOfFirstInstallment: yup
    .string()
    .required('Campo obrigatório')
    .test('valid-date', 'Data inválida', (value) => {
      return dayjs(value, { strict: true }).isValid();
    }),
  priceToBePaid: yup
    .number()
    .required('Campo obrigatório')
    .when('isToBePaidAfterService', {
      is: true,
      then: (schema) => schema.required('Campo obrigatório'),
      otherwise: () => yup.mixed().notRequired(),
    }),
  dateOfSinglePayment: yup
    .string()
    .required('Campo obrigatório')
    .when('isToBePaidAfterService', {
      is: true,
      then: (schema) =>
        schema
          .required('Campo obrigatório')
          .test('valid-date', 'Data inválida', (value) => {
            return dayjs(value, { strict: true }).isValid();
          }),
      otherwise: () => yup.mixed().notRequired(),
    }),
});

const schemaCreateCustomerPJ = yup.object().shape({
  name: yup.string().required('Campo obrigatório'),
  contractExpiration: yup
    .string()
    .required('Campo obrigatório')
    .test('valid-date', 'Data inválida', (value) => {
      return dayjs(value, { strict: true }).isValid();
    }),
  contractType: yup.string().required('Campo obrigatório'),
  saleDate: yup
    .string()
    .required('Campo obrigatório')
    .test('valid-date', 'Data inválida', (value) => {
      return dayjs(value, { strict: true }).isValid();
    }),
  socialReason: yup.string().required('Campo obrigatório'),
  isInternacional: yup.boolean(),
  'cep/ZIPCODE': yup
    .string()
    .required('Campo obrigatório')
    .when('isInternacional', {
      is: false,
      then: (schema) =>
        schema
          .required('Campo obrigatório')
          .matches(/^\d{5}-\d{3}$|^\d{5}$/, 'CEP/ZIPCODE inválido'),
      otherwise: () => yup.string().required('Campo obrigatório'),
    }),
  address: yup.string().required('Campo obrigatório'),
  addressNumber: yup.string().required('Campo obrigatório'),
  addressDistrict: yup.string().required('Campo obrigatório'),
  addressCity: yup.string().required('Campo obrigatório'),
  addressState: yup.string().required('Campo obrigatório'),
  legalRepresentativeName: yup.string().required('Campo obrigatório'),
  legalRepresentativeCpf: yup
    .string()
    .required('Campo obrigatório')
    .matches(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/, 'CPF inválido')
    .test('valid-cpf', 'CPF inválido', (value) => {
      return isCPFValid(value);
    }),
  email: yup.string().email('E-mail inválido').required('Campo obrigatório'),
  instalmentValue: yup.number().required('Campo obrigatório'),
  numberOfInstallments: yup.number().required('Campo obrigatório'),
  dateOfFirstInstallment: yup
    .string()
    .required('Campo obrigatório')
    .test('valid-date', 'Data inválida', (value) => {
      return dayjs(value, { strict: true }).isValid();
    }),
  priceToBePaid: yup
    .number()
    .required('Campo obrigatório')
    .when('isToBePaidAfterService', {
      is: true,
      then: (schema) => schema.required('Campo obrigatório'),
      otherwise: () => yup.mixed().notRequired(),
    }),
  dateOfSinglePayment: yup
    .string()
    .required('Campo obrigatório')
    .when('isToBePaidAfterService', {
      is: true,
      then: (schema) =>
        schema
          .required('Campo obrigatório')
          .test('valid-date', 'Data inválida', (value) => {
            return dayjs(value, { strict: true }).isValid();
          }),
      otherwise: () => yup.mixed().notRequired(),
    }),
});

const schemaCreateUserToCustomer = yup.object().shape({
  name: yup.string().required('Campo obrigatório'),
  email: yup.string().email('E-mail inválido').required('Campo obrigatório'),
});

export {
  schemaCreateCustomerPF,
  schemaCreateCustomerPJ,
  schemaCreateUserToCustomer,
};
