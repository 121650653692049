import React, { useEffect, useState } from 'react';
import { MdDriveFileRenameOutline } from 'react-icons/md';
import { Col, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import TitlePage from '../../../components/Pages/TitlePage';
import MainPage from '../../../components/Pages/MainPage';
import FormGeneric from '../../../components/Form';
import InputGeneric from '../../../components/Inputs/Input_generic';
import ButtonGeneric from '../../../components/Button';
import schemaCreateDemand from '../../../validators/schemas/demands/create';
import InputSelect from '../../../components/Inputs/Input_Select';
import FileInput from '../../../components/Inputs/Input_File';
import InputCheckbox from '../../../components/Inputs/Input_Checkbox';
import DEMAND_CONSTANTS from '../../../constants/demands';
import s3 from '../../../providers/urlGenerator';
import customerProvider from '../../../providers/customer';
import demandsProvider from '../../../providers/demands';
import userProvider from '../../../providers/user';
import businessProvider from '../../../providers/business';
import CustomerJourneyProvider from '../../../providers/CustomerJourney';
import InputsForFollowers from '../../../components/InputsForFollowers';

export default function CreateNewDemands() {
  const url = new URL(window.location.href.split('#/').join(''));
  const seach = (param) => url.searchParams.get(param);
  const navigate = useNavigate();

  const [customers, setCustomers] = useState([]);
  const [usersByCustomer, setUsersByCustomer] = useState([]);
  const [usersNotification, setUsersNotification] = useState([]);
  const [journeyList, setJourneyList] = useState([]);
  const [businessCustomer, setBusinessCustomer] = useState([]);

  const handleSubmit = async (data) => {
    const demandUserFollowingIds = [];
    for (const [key] of Object.entries(data)) {
      if (key?.split('__')[0] === 'demandUserFollowingIds' && data[key]) {
        demandUserFollowingIds.push(key.split('__')[1]);
      }
    }

    const attachments = await Promise.all(
      data?.attachments?.map(async (file) => {
        const uploaders = await s3.uploadFile(file);
        return uploaders;
      }),
    );

    const request = {
      name: data.name,
      description: data.description,
      customerId: data.customerId,
      journeyId: data.journeyId,
      following: [data.following],
      demandUserId: data.following,
      attachments,
      demandUserFollowingIds,
      businessId: data.businessId,
      private: data.private,
    };

    const demandResponse = await demandsProvider.create(request);
    if (demandResponse?.id) {
      navigate(`?demandId=${demandResponse?.id}`);
    }
  };

  const getUsersByBusiness = async (id) => {
    const usersResponse = await userProvider.getAll({
      where: [
        {
          key: '$Business.id$',
          value: id,
        },
      ],
      limit: 999,
    });
    setUsersByCustomer(usersResponse.rows);
  };

  const getJourneyUsersBusinessByCustomer = async (id) => {
    setUsersByCustomer([]);
    setJourneyList([]);
    setBusinessCustomer([]);

    const journeyResponse =
      await CustomerJourneyProvider.listAllCustomerJourneys({
        customerId: id,
      });
    const businessCustomerResponse = await businessProvider.getByCustomerId(id);
    setJourneyList(journeyResponse);
    setBusinessCustomer(businessCustomerResponse);
  };

  const fetch = async () => {
    const customersRequest = await customerProvider.getAll({
      limit: 1000,
    });
    setCustomers(customersRequest.rows);
    if (seach('customerId')) {
      const journeyResponse =
        await CustomerJourneyProvider.listAllCustomerJourneys({
          customerId: seach('customerId'),
        });
      const businessCustomerResponse = await businessProvider.getByCustomerId(
        seach('customerId'),
      );
      setJourneyList(journeyResponse);
      setBusinessCustomer(businessCustomerResponse);
    }
  };

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <TitlePage
        title="Criar uma nova Demanda"
        item="Demandas"
        description="Esta página é para adição de novos usuários à sua equipe do Backoffice. Personalize permissões avançadas para cada membro, garantindo acesso adequado às ferramentas necessárias. Controle com facilidade quem pode acessar áreas específicas do sistema."
      />
      <MainPage>
        <FormGeneric onSubmit={handleSubmit} schema={schemaCreateDemand}>
          <InputGeneric
            name="name"
            placeholder="Digite o nome"
            label="Nome*"
            defaultValue={seach('name') || ''}
            icon={MdDriveFileRenameOutline}
          />
          <InputGeneric
            as="textarea"
            name="description"
            placeholder="Digite a descrição detalhada"
            label="Descrição*"
            defaultValue={
              DEMAND_CONSTANTS.DEMAND_DESCRIPTIONS[seach('descriptionKey')] ||
              ''
            }
          />
          <Form.Label className="mt-3">Anexos</Form.Label>
          <FileInput name="attachments" />
          <Col
            style={{
              display: 'flex',
              gap: '1rem',
            }}
          >
            <InputSelect
              options={customers?.map((customer) => ({
                value: customer.id,
                label: customer.name,
              }))}
              defaultValue={seach('customerId') || null}
              placeholder="Selecione um Cliente"
              name="customerId"
              label="Cliente*"
              onChange={async (id) => {
                await getJourneyUsersBusinessByCustomer(id);
              }}
            />
            {businessCustomer?.length > 0 && (
              <InputSelect
                options={businessCustomer?.map((user) => ({
                  value: user.id,
                  label: user.name,
                }))}
                placeholder="Selecione um negócio"
                name="businessId"
                label="Negócio do cliente*"
                onChange={async (id) => {
                  await getUsersByBusiness(id);
                }}
              />
            )}
            {usersByCustomer?.length > 0 && (
              <InputSelect
                options={usersByCustomer?.map((user) => ({
                  value: user.id,
                  label: user.name,
                }))}
                placeholder="Selecione um Usuário"
                name="following"
                label="Usuário do cliente*"
                onChange={(id) => {
                  const user = usersByCustomer.filter((item) => item.id !== id);
                  setUsersNotification(user);
                }}
              />
            )}
          </Col>
          <InputsForFollowers canBeFollowers={usersNotification} formGeneric />
          {journeyList?.length > 0 && (
            <InputSelect
              options={journeyList?.map((journey) => ({
                value: journey?.id,
                label: journey?.journeyType?.title,
              }))}
              placeholder="Selecione uma Jornada"
              name="journeyId"
              label="Jornada"
              width="18rem"
            />
          )}
          <InputCheckbox
            className="mt-3 ms-1 border-top pt-3"
            name="private"
            label="Demanda Sigilosa"
          />
          <div className="pt-3 d-flex justify-content-end">
            <ButtonGeneric
              variant="success"
              className="text-white"
              type="submit"
            >
              Criar Demanda
            </ButtonGeneric>
          </div>
        </FormGeneric>
      </MainPage>
    </>
  );
}
