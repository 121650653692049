/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { HashRouter, Route, Routes, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PublicRoutes from './privateAndPublicRoutes/Public.routes';
import PrivateRoutes from './privateAndPublicRoutes/Private.routes';
import userProvider from '../providers/user';
import PoliticsRoutes from './privateAndPublicRoutes/Politics.routes';

export default function RoutesDefault() {
  const tokenLocalStorage = localStorage.getItem('@newndm-token');
  const authUser = useSelector((state) => state.auth.auth);

  const [token, setToken] = useState(tokenLocalStorage);

  const updatedToken = localStorage.getItem('@newndm-token');

  const dispatch = useDispatch();

  if (!token && updatedToken) setToken(updatedToken);
  if (tokenLocalStorage && updatedToken && authUser.length === 0) {
    userProvider.userLogged().then((user) => {
      if (user.Businesses.length > 0) {
        dispatch({ type: 'LOADED_BUSINESS', payload: user.Businesses });

        const businessInStorage = localStorage.getItem('businessId');
        if (
          !businessInStorage ||
          !user.Businesses.find((business) => business.id === businessInStorage)
        ) {
          localStorage.setItem('businessId', user.Businesses[0]?.id);
        }
      } else {
        localStorage.removeItem('businessId');
      }
      dispatch({ type: 'SET_AUTH', payload: user });
    });
    return null;
  }

  return (
    <HashRouter>
      <Routes>
        {/* Route de Login */}
        <Route path="/politicas/*" element={<PoliticsRoutes />} />
        <Route
          path="/login/*"
          element={token ? <Navigate to="/dashboard" /> : <PublicRoutes />}
        />

        {/* Routes Globais */}
        <Route
          path="*"
          element={!token ? <Navigate to="/login" /> : <PrivateRoutes />}
        />
      </Routes>
    </HashRouter>
  );
}
