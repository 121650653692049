import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { LiaFlagUsaSolid } from 'react-icons/lia';
import { HiOutlineIdentification } from 'react-icons/hi';
import { PiIdentificationBadgeDuotone, PiOfficeChair } from 'react-icons/pi';
import { TbCirclesRelation } from 'react-icons/tb';
import { useSelector } from 'react-redux';
import InputGeneric from '../../../components/Inputs/Input_generic';
import InputSelect from '../../../components/Inputs/Input_Select';
import InputCheckbox from '../../../components/Inputs/Input_Checkbox';
import InputMask from '../../../components/Inputs/Input_Mask';
import InputCalendar from '../../../components/Inputs/Input_Calendar';

export default function FormToPF() {
  const customer = useSelector((state) => state.customer);

  return (
    <>
      <Row>
        <Col>
          <InputGeneric
            name="rg"
            label="RG"
            placeholder="Digite o RG do cliente"
            icon={HiOutlineIdentification}
            defaultValue={customer?.data?.rg}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <InputGeneric
            name="cnh"
            label="CNH"
            placeholder="Digite o CNH do cliente"
            icon={PiIdentificationBadgeDuotone}
            defaultValue={customer?.data?.cnh}
          />
        </Col>
        <Col>
          <InputMask
            type="cpf"
            name="cpf"
            label="CPF"
            placeholder="Digite o CPF do cliente"
            icon={HiOutlineIdentification}
            defaultValue={customer?.data?.cpf.replace(
              /(\d{3})(\d{3})(\d{3})(\d{2})/,
              '$1.$2.$3-$4',
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <InputSelect
            name="sex"
            label="Sexo*"
            placeholder="Seleciona o sexo do cliente"
            options={[
              {
                value: 'M',
                label: 'Masculino',
              },
              {
                value: 'F',
                label: 'Feminino',
              },
            ]}
            defaultValue={customer?.data?.sex || 'M'}
          />
        </Col>
        <Col>
          <InputGeneric
            name="nationality"
            label="Nacionalidade"
            placeholder="Digite a nacionalidade do cliente"
            icon={LiaFlagUsaSolid}
            defaultValue={customer?.data?.nationality}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <InputGeneric
            name="civilState"
            label="Estado Civil"
            placeholder="Digite o estado civil do cliente"
            icon={TbCirclesRelation}
            defaultValue={customer?.data?.civilState}
          />
        </Col>
        <Col>
          <InputGeneric
            name="profession"
            label="Profissão"
            placeholder="Digite a profissão do cliente"
            icon={PiOfficeChair}
            defaultValue={customer?.data?.profession}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <InputCalendar
            type="date"
            name="birthDate"
            label="Data de Nascimento"
            placeholder="Selecione a data de nascimento"
            defaultValue={[customer?.data?.birthDate]}
          />
        </Col>
        <Col>
          <p className="fs-14 mb-2 mt-2 ms-1">Acesso a Ferramentas</p>
          <Row>
            <Col md={6}>
              <InputCheckbox
                name="customerAccess_Gerador de Documento"
                label="Gerador de Documento"
                defaultValue={customer?.data?.customerAccess?.includes(
                  'Gerador de Documento',
                )}
              />
            </Col>
            <Col md={6}>
              <InputCheckbox
                name="customerAccess_Mapeamento de dados"
                label="Mapeamento de dados"
                defaultValue={customer?.data?.customerAccess?.includes(
                  'Mapeamento de dados',
                )}
              />
            </Col>
            <Col md={6}>
              <InputCheckbox
                name="customerAccess_Registrar marcas"
                label="Registrar marcas"
                defaultValue={customer?.data?.customerAccess?.includes(
                  'Registrar marcas',
                )}
              />
            </Col>
            <Col md={6}>
              <InputCheckbox
                name="customerAccess_Análise Interna de Risco"
                label="Análise Interna de Risco"
                defaultValue={customer?.data?.customerAccess?.includes(
                  'Análise Interna de Risco',
                )}
              />
            </Col>
            <Col md={6}>
              <InputCheckbox
                name="customerAccess_Lembretes"
                label="Lembretes"
                defaultValue={customer?.data?.customerAccess?.includes(
                  'Lembretes',
                )}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}
