import React, { useRef, useState } from 'react';
import { Card } from 'react-bootstrap';
import { MdDelete } from 'react-icons/md';
import { BsFillCloudArrowDownFill } from 'react-icons/bs';
import { BiDownload } from 'react-icons/bi';
import templateString from 'template-string';
import Editor from '@draft-js-plugins/editor';
import createLinkifyPlugin from '@draft-js-plugins/linkify';
import { CompositeDecorator, convertFromRaw, EditorState } from 'draft-js';
import { useDispatch, useSelector } from 'react-redux';
import DateUtils from '../utils/DateUtils';
import GenericUtils, { LinkifyText, lineBreak } from '../utils/GenericUtils';
import s3 from '../providers/urlGenerator';
import ModalConfirmation from './Modals/ModalConfirmation';
import demandsProvider from '../providers/demands';

const linkifyPlugin = createLinkifyPlugin({
  target: '_blank',
});
const plugins = [linkifyPlugin];
const [decorators] = plugins.map((plugin) => plugin.decorators);
const decorator = new CompositeDecorator(decorators);

function Message({ message, ...rest }) {
  const hasJson = GenericUtils.tryParseJSON(message);

  const inputRef = useRef(null);

  if (!hasJson) {
    return (
      <LinkifyText
        properties={{
          target: '_blank',
          style: {
            fontWeight: 'bold',
          },
        }}
      >
        {lineBreak(message)}
      </LinkifyText>
    );
  }

  const editorState = EditorState.createWithContent(
    convertFromRaw(hasJson),
    decorator,
  );

  return editorState ? (
    <Editor
      editorState={editorState}
      // onChange={(state) => setEditorState(state)}
      plugins={plugins}
      ref={inputRef}
      readOnly
      {...rest}
    />
  ) : (
    <div>Carregando ...</div>
  );
}

async function redirectToDownloadUrl(key, fileName) {
  const { signedUrl } = await s3.getSignedDownloadFileUrl(key);

  s3.downloadFileByUrl(signedUrl, fileName);
}

export default function MessageCard({
  messageDirection,
  showDeleteButton,
  deleteFunction,
  ...props
}) {
  const [openModalDeleteMessageDemand, setOpenModalDeleteMessageDemand] =
    useState(false);
  const demand = useSelector((state) => state.demand.data);
  const authUser = useSelector((state) => state.auth.auth);

  let showDeleteButtonBackoffice = false;
  if (authUser.type !== 'CUSTOMER') {
    showDeleteButtonBackoffice = true;
  }

  const dispath = useDispatch();

  const calculateMessageDirection = (internDirection) => {
    return messageDirection || internDirection;
  };

  let positionMessage = calculateMessageDirection('flex-start');
  let colorCard = '#F2F5FA';
  colorCard = props?.colorCard ? props.colorCard : colorCard;
  let colorText = props?.colorText ? props.colorText : undefined;
  if (props) {
    if (authUser.type === 'BACKOFFICE') {
      if (props.isMessageOfBackoffice) {
        colorCard = '#FFE0BE';
        positionMessage = calculateMessageDirection('flex-end');
      }
      if (props.isPrivate) {
        colorCard = '#dafbff';
        positionMessage = calculateMessageDirection('flex-end');
      }
      if (props.isDeleted) {
        colorCard = '#ff9191';
        colorText = 'white';
        positionMessage = calculateMessageDirection('flex-end');
      }
    } else if (!props.isMessageOfBackoffice) {
      positionMessage = calculateMessageDirection('flex-end');
      colorCard = '#FFE0BE';
    }
  }

  let message = props?.message;

  if (props?.type === 'TEMPLATED_MESSAGE' && message !== '') {
    const contentInMessage = JSON.parse(message);

    if (contentInMessage.parameters.oldDemand_deleveryForecast) {
      const date = DateUtils.formatToLocale(
        contentInMessage.parameters.oldDemand_deleveryForecast,
      );
      contentInMessage.parameters.oldDemand_deleveryForecast =
        date !== ''
          ? date
          : contentInMessage.parameters.oldDemand_deleveryForecast;
    }
    if (contentInMessage.parameters.demand_deleveryForecast) {
      contentInMessage.parameters.demand_deleveryForecast =
        DateUtils.formatToLocale(
          contentInMessage.parameters.demand_deleveryForecast,
        );
    }
    message = templateString(
      contentInMessage.message,
      contentInMessage.parameters,
    );
  }

  if (props?.type === 'INTERN_TEMPLATED_MESSAGE' && message !== '') {
    const contentInMessage = JSON.parse(message);

    if (contentInMessage.parameters.oldDemand_internDeliveryForecast) {
      const date = DateUtils.formatToLocale(
        contentInMessage.parameters.oldDemand_internDeliveryForecast,
      );
      contentInMessage.parameters.oldDemand_internDeliveryForecast =
        date !== ''
          ? date
          : contentInMessage.parameters.oldDemand_internDeliveryForecast;
    }
    if (contentInMessage.parameters.demand_internDeliveryForecast) {
      contentInMessage.parameters.demand_internDeliveryForecast =
        DateUtils.formatToLocale(
          contentInMessage.parameters.demand_internDeliveryForecast,
        );
    }
    message = templateString(
      contentInMessage.message,
      contentInMessage.parameters,
    );
  }

  let attachmentBackgroundColor = '#E6E9F5';

  if (props?.isMessageOfBackoffice === true) {
    if (authUser.type !== 'CUSTOMER') {
      attachmentBackgroundColor = '#F5D1A1';
    } else {
      attachmentBackgroundColor = '#E6E9F5';
    }
  } else if (authUser.type !== 'CUSTOMER') {
    attachmentBackgroundColor = '#E6E9F5';
  } else {
    attachmentBackgroundColor = '#F5D1A1';
  }

  if (props?.isPrivate === true) {
    attachmentBackgroundColor = '#baefef';
  }

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: positionMessage,
        marginBottom: '1rem',
      }}
    >
      <Card
        className="d-flex"
        style={{
          background: colorCard,
          color: colorText || '#000000',
          border: 'none',
          borderRadius: '0.5rem',
          width: 'fit-content',
          minWidth: '10rem',
          maxWidth: '80%',
          padding: '0 0.7rem',
          margin: '0 0.5rem',
        }}
      >
        <Card.Title className="d-flex justify-content-between fs-12 ms-2 pt-3 font-bold">
          <p>{props?.user}</p>
          <p
            style={{
              color: colorText || '#888888',
              fontWeight: '300',
              margin: '0',
              marginTop: '-0.4rem',
            }}
          >
            {props?.date}
            {((!props?.isPrivate &&
              props?.isMessageOfBackoffice &&
              !props?.isDeleted &&
              showDeleteButtonBackoffice) ||
              (!props?.isDeleted && showDeleteButton)) && (
              <MdDelete
                className="text-danger hover-pointer ms-2"
                size={20}
                onClick={() => {
                  setOpenModalDeleteMessageDemand(true);
                }}
              />
            )}
          </p>
        </Card.Title>
        <Card.Body
          style={{
            padding: '0rem 1rem 1rem 0.5rem',
            fontWeight: '300',
            lineHeight: '1.6',
            textAlign: 'justify',
          }}
        >
          <Message message={message} />
          {props?.attachments?.length > 0 &&
            props?.attachments.map((attachment, index) => (
              <div
                className="d-flex align-items-center mt-1 ps-2 pe-2 fs-14"
                style={{
                  width: '100%',
                  height: '2.5rem',
                  background: attachmentBackgroundColor,
                  border: 'none',
                }}
              >
                <div
                  className="d-flex"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textAlign: 'start',
                    width: '100%',
                  }}
                >
                  <BsFillCloudArrowDownFill
                    size={25}
                    className="me-3 text-white"
                  />
                  <p
                    className="m-0 fs-12"
                    style={{
                      display: 'flex',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      width: '100%',
                    }}
                  >
                    {attachment.fileName}
                  </p>
                </div>
                <div className="d-flex">
                  <BiDownload
                    role="button"
                    onClick={() =>
                      redirectToDownloadUrl(
                        attachment.awsFileKey,
                        attachment.fileName,
                      )
                    }
                    size={25}
                    className="text-info hover ms-3"
                  />
                </div>
              </div>
            ))}
        </Card.Body>
        <ModalConfirmation
          open={openModalDeleteMessageDemand}
          close={() => setOpenModalDeleteMessageDemand(false)}
          onConfirmation={async () => {
            if (deleteFunction) {
              await deleteFunction(props);
              return setOpenModalDeleteMessageDemand(false);
            }
            await demandsProvider.deleteMessage(props?.data?.id);
            const demandResponse = await demandsProvider.getDemandById(
              demand?.id,
            );
            dispath({
              type: 'SET_DEMANDS_STATE',
              payload: {
                data: {
                  demand,
                  ...demandResponse,
                },
              },
            });
            return setOpenModalDeleteMessageDemand(false);
          }}
          title="Excluir Mensagem"
          description="Tem certeza que deseja excluir essa Mensagem ? Essa ação não poderá ser desfeita!"
        />
        {props.data.deletedByUserName && (
          <div className="fs-12 d-flex justify-content-end text-white text-bold">
            <p
              style={{
                color: colorText || '#000000',
              }}
              className="m-0"
            >
              mensagem deletada por {props.data.deletedByUserName}
            </p>
          </div>
        )}
      </Card>
    </div>
  );
}
