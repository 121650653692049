import CONSTANTE_CUSTOMER from '../../../constants/customer';
import setStoreRedux, { getStoreRedux } from '../../../utils/setStore';

export const onChangeTags = async ({
  e,
  operator = 'contains',
  name = '$Customer.tags$',
}) => {
  let dataRedux =
    Object.values(getStoreRedux('customerList')?.where || {})?.length > 0
      ? getStoreRedux('customerList')?.where || []
      : [];
  dataRedux = dataRedux.filter((data) => data.key !== name);

  if (e.length > 0) {
    dataRedux.push({
      key: name,
      operator,
      value: e,
      model: 'Tag',
      columnModel: 'id',
    });
  }

  setStoreRedux('SET_CUSTOMER_STATE', {
    where: dataRedux,
  });
};

export const selects = (businesses, tags) => [
  {
    name: 'typeCompany',
    label: 'Tipo de sociedade',
    onChange: async (e) => {
      const dataRedux =
        Object.values(getStoreRedux('customerList')?.where || {})?.length > 0
          ? getStoreRedux('customerList')?.where || []
          : [];
      setStoreRedux('SET_CUSTOMER_STATE', {
        where: [
          ...dataRedux,
          {
            key: 'typeCompany',
            operator: 'iLike',
            value: e,
          },
        ],
      });
    },
    options: CONSTANTE_CUSTOMER.SOCIETY_TYPES,
    placeholder: 'Selecione o tipo de sociedade',
  },
  {
    name: 'businessType',
    label: 'Tipo de negócio',
    onChange: async (e) => {
      const dataRedux =
        Object.values(getStoreRedux('customerList')?.where || {})?.length > 0
          ? getStoreRedux('customerList')?.where || []
          : [];
      setStoreRedux('SET_CUSTOMER_STATE', {
        where: [
          ...dataRedux,
          {
            key: 'businessType',
            operator: 'iLike',
            value: e,
          },
        ],
      });
    },
    options: CONSTANTE_CUSTOMER.CUSTOMER_TYPES.OPTIONS,
    placeholder: 'Selecione o tipo de negócio',
  },
  {
    name: 'personType',
    label: 'Tipo de pessoa',
    onChange: async (e) => {
      const dataRedux =
        Object.values(getStoreRedux('customerList')?.where || {})?.length > 0
          ? getStoreRedux('customerList')?.where || []
          : [];
      setStoreRedux('SET_CUSTOMER_STATE', {
        where: [
          ...dataRedux,
          {
            key: 'personType',
            operator: 'iLike',
            value: e,
          },
        ],
      });
    },
    options: [
      { label: 'PF', value: 'PF' },
      { label: 'PJ', value: 'PJ' },
    ],
    placeholder: 'Selecione o tipo de pessoa',
  },
  {
    name: 'addressState',
    label: 'Estado',
    onChange: async (e) => {
      const dataRedux =
        Object.values(getStoreRedux('customerList')?.where || {})?.length > 0
          ? getStoreRedux('customerList')?.where || []
          : [];
      setStoreRedux('SET_CUSTOMER_STATE', {
        where: [
          ...dataRedux,
          {
            key: 'addressState',
            operator: 'iLike',
            value: e,
          },
        ],
      });
    },
    options: [...new Set(businesses?.map((business) => business.addressState))]
      .map((state) => ({
        value: state,
        label: state,
      }))
      .filter((state) => state.label !== null && state.key !== null)
      .sort((a, b) => a.label.localeCompare(b.label)),
    placeholder: 'Selecione o estado',
  },
  {
    name: 'addressCity',
    label: 'Cidade',
    onChange: async (e) => {
      const dataRedux =
        Object.values(getStoreRedux('customerList')?.where || {})?.length > 0
          ? getStoreRedux('customerList')?.where || []
          : [];
      setStoreRedux('SET_CUSTOMER_STATE', {
        where: [
          ...dataRedux,
          {
            key: 'addressCity',
            operator: 'iLike',
            value: e,
          },
        ],
      });
    },
    options: [...new Set(businesses?.map((business) => business.addressCity))]
      .map((city) => ({
        value: city,
        label: city,
      }))
      .sort((a, b) => a.label.localeCompare(b.label)),
    placeholder: 'Selecione a cidade',
  },
  {
    name: 'tagId',
    label: 'Tags',
    onChange: async (e) => {
      onChangeTags({ e });
    },
    options: Array.from(
      new Set(
        businesses
          ?.map((business) => business.Customer?.tags?.find((tag) => tag))
          .filter((e) => e !== undefined)
          .map((tag) => tag.id),
      ),
    )
      .map((tagId) => {
        const tag = businesses
          ?.map((business) =>
            business.Customer?.tags?.find((tag_) => tag_ && tag_.id === tagId),
          )
          .filter((e) => e !== undefined)[0];
        return {
          value: tag.id,
          label: tag.name,
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label)),
    placeholder: 'Selecione a tag',
  },
  {
    name: 'notTagId',
    label: 'Remover cliente com as tags:',
    onChange: async (e) => {
      onChangeTags({ e, name: '$Customer.NotTags$' });
    },
    options: tags
      ?.map((tag) => ({
        value: tag.id,
        label: tag.name,
      }))
      .sort((a, b) => a.label.localeCompare(b.label)),
    placeholder: 'Selecione a tag',
  },
];

export const columnName = [
  {
    name: 'Negócio',
    columnName: 'name',
    selector: (row) => row.name,
    sortable: true,
  },
  {
    name: 'Cliente',
    columnName: 'customer',
    selector: (row) => row?.customer,
    orderArray: [{ model: 'Customer', as: 'customer' }, 'name'],
    sortable: true,
  },
  {
    name: 'Tipo de Negócio',
    columnName: 'businessType',
    selector: (row) => row.businessType,
    sortable: true,
  },
];

export const dataColumnTable = (customers) => {
  return customers?.map((e) => {
    return {
      name: e.name,
      customer: e?.Customer?.name,
      businessType: e.businessType,
    };
  });
};
