/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Table from '../../components/Table/Table';
import DocumentProvider from '../../providers/documentProvider';
import DateUtils from '../../utils/DateUtils';
import SmartLink from '../../components/SmartLink';
import DownloadIcon from '../../components/customIcons/DownloadIcon';
import s3 from '../../providers/urlGenerator';
import TitlePage from '../../components/Pages/TitlePage';
import FormGeneric from '../../components/Form';
import InputGeneric from '../../components/Inputs/Input_generic';
import ButtonGeneric from '../../components/Button';
import FilterIcon from '../../components/customIcons/FilterIcon';
import DocumentIconMini from '../../components/customIcons/DocumentIconMini';
import InputSelect from '../../components/Inputs/Input_Select';
import ModalConfirmation from '../../components/Modals/ModalConfirmation';
import { defaultGetAllParams } from '../../utils/params';

function DemandLink({ row }) {
  return (
    <SmartLink
      className="text-black text-decoration-none cursor-pointer"
      to={`/demands/${row?.demand?.id}`}
    >
      <DocumentIconMini
        className="me-1"
        padded={false}
        width={10}
        height={14}
      />{' '}
      {row?.demand?.name}
    </SmartLink>
  );
}

function DownloadButton({ row }) {
  return (
    <div
      onClick={async () => {
        const { signedUrl } = await s3.getSignedDownloadFileUrl(
          row?.awsFileKey,
        );
        s3.downloadFileByUrl(signedUrl, row?.fileName);
      }}
    >
      <DownloadIcon padded={false} />
    </div>
  );
}

export default function DocumentsList() {
  const dispatch = useDispatch();
  const documentList = useSelector((state) => state.documentList);

  const [
    showDownloadAllConfirmationModal,
    setShowDownloadAllConfirmationModal,
  ] = useState(false);

  const onSubmit = (data) => {
    const where = [];
    if (data.createdAtInitial && data.createdAtFinal) {
      where.push({
        key: 'createdAt',
        value: [data.createdAtInitial, data.createdAtFinal],
        operator: 'between',
      });
    } else {
      if (data.createdAtInitial) {
        where.push({
          key: 'createdAt',
          value: data.createdAtInitial,
          operator: 'gte',
        });
      }
      if (data.createdAtFinal) {
        where.push({
          key: 'createdAt',
          value: data.createdAtFinal,
          operator: 'lte',
        });
      }
    }
    if (data.fileName) {
      where.push({
        key: 'fileName',
        value: data.fileName,
        operator: 'iLike',
      });
    }
    if (data.sendedBy !== 'all') {
      where.push({
        key: '$user.type$',
        value: data.sendedBy,
        operator: 'eq',
      });
    }
    dispatch({
      type: 'SET_DOCUMENT_LIST',
      payload: {
        where,
      },
    });
  };

  const columnsDocuments = [
    {
      name: 'Nome do Arquivo',
      columnName: 'fileName',
      selector: (row) => row?.fileName,
      sortable: true,
    },
    {
      name: 'Enviado em',
      columnName: 'createdAt',
      selector: (row) => DateUtils.formatToLocale(row?.createdAt),
      sortable: true,
    },
    {
      name: 'Demanda',
      orderArray: [{ model: 'Demand', as: 'demand' }, 'name'],
      selector: (row) => DemandLink({ row }),
      sortable: true,
    },
    {
      name: 'Ações',
      columnName: 'actions',
      selector: (row) => DownloadButton({ row }),
    },
  ];

  return (
    <>
      <TitlePage title="Documentos" />
      <FormGeneric onSubmit={onSubmit}>
        <Row className="align-items-end m-0">
          <Col xs="6" xxl="2">
            <InputGeneric
              name="createdAtInitial"
              label="Data inicial"
              placeholder="De"
              required={false}
              type="date"
            />
          </Col>
          <Col xs="6" xxl="2">
            <InputGeneric
              name="createdAtFinal"
              label="Data final"
              placeholder="Até"
              required={false}
              type="date"
            />
          </Col>
          <Col xs="12" lg="6" xxl="5">
            <InputGeneric
              name="fileName"
              label="Nome do documento"
              placeholder="Revisão de contrato, lgdp, etc"
            />
          </Col>
          <Col>
            <InputSelect
              name="sendedBy"
              label="Enviado por"
              defaultValue="all"
              options={[
                { label: 'Cliente', value: 'CUSTOMER' },
                { label: 'NDM', value: 'BACKOFFICE' },
                { label: 'Todos', value: 'all' },
              ]}
            />
          </Col>
          <Col xs="auto">
            <ButtonGeneric type="submit" className="mb-1">
              <FilterIcon style={{ fill: 'white' }} padded={false} />
            </ButtonGeneric>
          </Col>
        </Row>
      </FormGeneric>
      <Table
        className="mt-5"
        columns={columnsDocuments}
        pagination
        paginationServer
        fetchFunction={DocumentProvider.getAll}
        dispatchString="DOCUMENT_LIST"
        listReducerKey="documentList"
        hideXmsButton
        unStyled
      />
      <Row className="m-0">
        <Col xs="auto" className="ms-auto mt-3">
          <ButtonGeneric
            type="button"
            className=" mb-1"
            onClick={() => setShowDownloadAllConfirmationModal(true)}
          >
            <DownloadIcon padded={false} />
            Baixar todos
          </ButtonGeneric>
        </Col>
      </Row>
      <ModalConfirmation
        open={showDownloadAllConfirmationModal}
        close={() => setShowDownloadAllConfirmationModal(false)}
        onConfirmation={async () => {
          try {
            await DocumentProvider.downloadAll({
              ...defaultGetAllParams,
              where: documentList.where,
            });
            toast.success(
              'Você receberá um e-mail com o link para download dos documentos.',
            );
            setShowDownloadAllConfirmationModal(false);
          } catch (error) {
            toast.error(
              'Erro ao processar a solicitação. Tente novamente mais tarde.',
            );
          }
        }}
        title="Deseja fazer o download de todos os documentos?"
        description="Caso confirme, iremos enviar um e-mail para você assim que tudo estiver disponível para download em um link."
      />
    </>
  );
}
