/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

function RoundedPill({ validatedSelected, onClick, children, extraClasses }) {
  return (
    <span
      className={` fs-12 p-2 rounded-pill m-2 ${
        !validatedSelected ? 'bg-selected' : 'bg-unselected cursor-pointer'
      } badge`}
      onClick={onClick}
    >
      {children}
    </span>
  );
}

export default RoundedPill;
