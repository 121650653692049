/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import 'draft-js/dist/Draft.css';
import { BsPaperclip } from 'react-icons/bs';
import { AiOutlineClose } from 'react-icons/ai';

import Editor from '@draft-js-plugins/editor';
import {
  convertToRaw,
  EditorState,
  CompositeDecorator,
  convertFromRaw,
} from 'draft-js';
import createToolbarPlugin, {
  Separator,
} from '@draft-js-plugins/static-toolbar';
import {
  ItalicButton,
  BoldButton,
  UnderlineButton,
  UnorderedListButton,
  OrderedListButton,
} from '@draft-js-plugins/buttons';
import createLinkifyPlugin from '@draft-js-plugins/linkify';
import { useDispatch } from 'react-redux';
import ButtonGeneric from '../Button';
import '@draft-js-plugins/static-toolbar/lib/plugin.css';
import '@draft-js-plugins/linkify/lib/plugin.css';
import { store } from '../../store/index';

const decoratorLinkifyPlugin = createLinkifyPlugin({
  target: '_blank',
});
const decoratorPlugins = [decoratorLinkifyPlugin];
const [decorators] = decoratorPlugins.map((plugin) => plugin.decorators);
const decorator = new CompositeDecorator(decorators);

function RichTextArea({ cantAttachFiles, ...props }) {
  const dispatch = useDispatch();

  const getFiles = () => {
    let files = [];
    try {
      files = store.getState().files[props.keyId][props.saveToType] || [];
    } catch (error) {
      files = [];
    }
    return files;
  };

  const [filesToUploadState, setFilesToUploadState] = useState(() =>
    getFiles(),
  );

  const [editorState, setEditorState] = useState(() => {
    let drafts = '';
    try {
      drafts = store.getState().drafts[props.keyId][props.saveToType] || '';
    } catch (error) {
      drafts = '';
    }
    if (drafts === '' || drafts === 'NeedToBeCleaned') {
      return EditorState.createEmpty();
    }
    return EditorState.createWithContent(
      convertFromRaw(JSON.parse(drafts)),
      decorator,
    );
  });

  const [{ plugins, ToolbarComponent }] = useState(() => {
    const toolbarPlugin = createToolbarPlugin();
    const linkifyPlugin = createLinkifyPlugin();
    const { Toolbar } = toolbarPlugin;
    const pluginsOfToolbar = [toolbarPlugin, linkifyPlugin];

    return {
      plugins: pluginsOfToolbar,
      ToolbarComponent: Toolbar,
    };
  });

  const inputRef = useRef(null);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      setFilesToUploadState([...acceptedFiles]);
      dispatch({
        type: 'SET_SELECTED_FILES_STATE',
        payload: {
          keyId: props.keyId,
          saveToType: props.saveToType,
          files: [...acceptedFiles],
        },
      });
      setFilesToUploadState(getFiles());
    },
  });

  const processText = (optionalState = null) => {
    const editorStateToProcess = optionalState || editorState;
    return editorStateToProcess.getCurrentContent().hasText()
      ? JSON.stringify(convertToRaw(editorStateToProcess.getCurrentContent()))
      : '';
  };

  const handleOnChange = (state) => {
    console.log('Alterando estado do editor');
    setEditorState(state);
    console.log('Estado do editor alterado');

    console.log('Processando texto');
    const draft = processText(state);
    console.log('Texto processado');

    console.log('Salvando rascunho');
    dispatch({
      type: 'SET_DRAFT_BY_KEY_ID_AND_SAVE_TO_TYPE',
      payload: {
        keyId: props.keyId,
        saveToType: props.saveToType,
        draft,
      },
    });
    console.log('Rascunho salvo');
  };

  useEffect(() => {
    props?.setClearRichText(() => {
      return async () => {
        setEditorState(EditorState.createEmpty());
        dispatch({
          type: 'RESET_SELECTED_FILES_STATE',
          payload: {
            keyId: props.keyId,
            saveToType: props.saveToType,
          },
        });
        // eslint-disable-next-line no-promise-executor-return
        await new Promise((resolve) => setTimeout(resolve, 300));
      };
    });
  }, [props.keyId, props.saveToType]);

  useEffect(() => {
    setFilesToUploadState(getFiles());
  }, [editorState]);

  console.log('Renderizando RichTextArea');
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        overflowY: 'hidden',
        padding: '0.5rem',
      }}
    >
      <div
        className="richArea"
        style={{
          height: '100%',
          overflowY: 'auto',
        }}
      >
        <Editor
          onChange={handleOnChange}
          editorState={editorState}
          plugins={plugins}
          ref={(element) => {
            if (element === null) return;
            inputRef.current = element;
            if (props?.register) {
              props.register(props?.name, {
                value: {
                  [props?.name]: processText(),
                  attachments: filesToUploadState,
                },
              });
            }
          }}
        />
        <ToolbarComponent className="toolbar">
          {(rest) => (
            <>
              <BoldButton {...rest} />
              <ItalicButton {...rest} />
              <UnderlineButton {...rest} />
              <Separator {...rest} />
              <UnorderedListButton {...rest} />
              <OrderedListButton {...rest} />
              <Separator {...rest} />
              {!cantAttachFiles && (
                <ButtonGeneric type="button" variant="white" className="hover">
                  <input {...getInputProps()} />
                  <BsPaperclip
                    size={22}
                    {...getRootProps()}
                    className="mb-1"
                    style={{
                      marginLeft: '-0.8rem',
                    }}
                    color="#777777"
                  />
                </ButtonGeneric>
              )}
              {filesToUploadState?.length > 0 && (
                <ButtonGeneric type="button" variant="white">
                  <span>{filesToUploadState.length} Arquivos</span>{' '}
                  <AiOutlineClose
                    size={10}
                    color="red"
                    onClick={(e) => {
                      e.stopPropagation();
                      dispatch({
                        type: 'RESET_SELECTED_FILES_STATE',
                        payload: {
                          keyId: props.keyId,
                          saveToType: props.saveToType,
                        },
                      });
                      setFilesToUploadState(getFiles());
                    }}
                  />
                </ButtonGeneric>
              )}
            </>
          )}
        </ToolbarComponent>
      </div>
    </div>
  );
}

export default RichTextArea;
