/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import TitlePage from '../../../components/Pages/TitlePage';
import MainPage from '../../../components/Pages/MainPage';
import ButtonGeneric from '../../../components/Button';
import ModalConfirmation from '../../../components/Modals/ModalConfirmation';
import CustomerMappingProvider from '../../../providers/CustomerMapping';
import DEMAND_CONSTANTS from '../../../constants/demands';
import HourEmptyEmptyIcon from '../../../components/customIcons/HourGlassEmptyIcon';
import CardForContent from '../../../components/CardForContent';
import PartIcon from '../../../components/customIcons/PartIcon';
import CustomerSectorProvider from '../../../providers/CustomerSector';
import { CUSTOMER_SECTORS } from '../../../constants/customer';
import RoundedPill from '../../../components/RoundedPill';
import CloseIcon from '../../../components/customIcons/CloseIcon';
import VisibilityOnIcon from '../../../components/customIcons/VisibilityOnIcon';
import ModalForm from '../../../components/Modals/ModalForm';
import InputGeneric from '../../../components/Inputs/Input_generic';
import MappingIcon from '../../../components/customIcons/MappingIcon';
import Table from '../../../components/Table/Table';
import DateUtils from '../../../utils/DateUtils';
import s3 from '../../../providers/urlGenerator';
import DownloadIcon from '../../../components/customIcons/DownloadIcon';
import CustomerMappingDocumentProvider from '../../../providers/CustomerMappingDocument';
import userProvider from '../../../providers/user';
import DemandIcon from '../../../components/customIcons/DemandIcon';
import SmartLink from '../../../components/SmartLink';

function DownloadButton({ row }) {
  return (
    <div
      onClick={async () => {
        const { signedUrl } = await s3.getSignedDownloadFileUrl(
          row?.awsFileKey,
        );
        s3.downloadFileByUrl(signedUrl, row?.fileName);
      }}
    >
      <DownloadIcon padded={false} />
    </div>
  );
}

const columnsDocuments = [
  {
    name: 'Nome do Arquivo',
    columnName: 'fileName',
    selector: (row) => row?.fileName,
    sortable: false,
  },
  {
    name: 'Enviado em',
    columnName: 'createdAt',
    selector: (row) => DateUtils.formatToLocale(row?.createdAt),
    sortable: false,
  },
  {
    name: 'Ações',
    columnName: 'actions',
    selector: (row) => DownloadButton({ row }),
  },
];

export default function ToolsMappingIndex() {
  const navigate = useNavigate();
  const [showModalRemake, setShowModalRemake] = useState(false);
  const [showModalReview, setShowModalReview] = useState(false);
  const [showModalRemoveSector, setShowModalRemoveSector] = useState(false);
  const [showModalInsertOtherSector, setShowModalInsertOtherSector] =
    useState(false);

  const authUser = useSelector((state) => state.auth.auth);

  const customerMapping = useSelector((state) => {
    return state.CustomerMappingList;
  });

  const customerSector = useSelector((state) => {
    return state.CustomerSectorList;
  });

  const fetch = async () => {
    await CustomerMappingProvider.get().then((data) => {
      if (!data.demandId) {
        if (data.preMeetingDemand) {
          navigate(`/demand/${data.preMeetingDemand.id}`);
        }
      }
      dispatch({
        type: 'SET_CUSTOMER_MAPPING_LIST',
        payload: { selected: data },
      });
    });
    await CustomerSectorProvider.getAll().then((data) => {
      dispatch({
        type: 'SET_CUSTOMER_SECTOR_LIST',
        payload: { rows: data.rows, count: data.count },
      });
    });
  };

  const dispatch = useDispatch();
  useEffect(() => {
    fetch();
  }, []);

  const reopenMapping = async () => {
    CustomerMappingProvider.reOpen();
    navigate(`/demand/${customerMapping.selected.preMeetingDemand.id}`);
    setShowModalRemake(false);
  };

  const reviewMapping = async () => {
    CustomerMappingProvider.review().then(() => {
      navigate(`/demand/${customerMapping.selected.mappingDemand.id}`);
    });
    setShowModalReview(false);
  };

  const MappingButtons = {
    [DEMAND_CONSTANTS.DEMAND_STATUS_KEYS.OPEN.key]: undefined,
    [DEMAND_CONSTANTS.DEMAND_STATUS_KEYS.PENDING.key]: (
      <ButtonGeneric
        onClick={() => setShowModalReview(true)}
        className="text-white"
      >
        Enviar para revisão
      </ButtonGeneric>
    ),
    [DEMAND_CONSTANTS.DEMAND_STATUS_KEYS.BLOCKED.key]: undefined,
    [DEMAND_CONSTANTS.DEMAND_STATUS_KEYS.IN_PROGRESS.key]: (
      <ButtonGeneric
        onClick={() =>
          navigate(`/demand/${customerMapping.selected.mappingDemand.id}`)
        }
        variant="link"
        className="text-info text-decoration-none"
      >
        Mapeamento em revisão. Basta aguardar{' '}
        <HourEmptyEmptyIcon fill="#3A85F7" padded={false} />
      </ButtonGeneric>
    ),
    [DEMAND_CONSTANTS.DEMAND_STATUS_KEYS.FINISHED.key]: (
      <ButtonGeneric
        onClick={() => setShowModalRemake(true)}
        variant="outline-primary"
        className="bg-white"
      >
        Refazer mapeamento de dados
      </ButtonGeneric>
    ),
  };

  const nonCustomerSectorsToShow = CUSTOMER_SECTORS.filter((sector) => {
    return !customerSector.rows.some(
      (sectorOfCustomer) => sectorOfCustomer.sectorName === sector,
    );
  });

  const removeSector = async () => {
    await CustomerSectorProvider.remove(customerSector.selected.id);
    await fetch();
    setShowModalRemoveSector(false);
  };

  const insertSector = async (sectorName) => {
    await CustomerSectorProvider.insert({ sectorName });
    await fetch();
  };

  const insertOtherSector = async (data) => {
    await insertSector(data.sectorName);
    setShowModalInsertOtherSector(false);
  };

  return (
    <>
      <TitlePage
        title={
          <Row className="m-0">
            <Col className="p-0 m-0">
              <h3>Mapeamentos</h3>
            </Col>
            <Col xs="auto">
              {
                MappingButtons[
                  customerMapping.selected?.mappingDemand?.statusKey
                ]
              }
            </Col>
          </Row>
        }
        border="none"
        item="Ferramentas"
      />
      <MainPage>
        <CardForContent
          height=""
          Icon={PartIcon}
          title={
            customerMapping.selected?.mappingDemand?.statusKey ===
            'IN_PROGRESS' ? (
              <>
                <h6>Demanda em progresso</h6>

                <p className="fs-12 fw-normal m-0">
                  Nossa equipe está analisando o mapeamento e não é possível
                  alterar os dados. Enquanto isso, pode mandar mensagem para nós
                  clicando abaixo.
                </p>
              </>
            ) : (
              'Setores'
            )
          }
          hasCard={false}
          className="ps-2 pe-2"
        >
          {customerMapping.selected?.mappingDemand?.statusKey ===
          'IN_PROGRESS' ? (
            <SmartLink
              to={`/demand/${customerMapping.selected?.mappingDemand?.id}`}
            >
              <p className="m-0" style={{ color: '#515151' }}>
                <DemandIcon padded={false} fill="#515151" />{' '}
                <strong className="align-middle"> Falar com a gente</strong>
              </p>
            </SmartLink>
          ) : (
            <>
              <p className="ms-2 mb-0">Seus setores</p>
              {customerSector.rows.map((sector) => (
                <RoundedPill key={sector.id} validatedSelected={false}>
                  <Row className="m-0">
                    <Col
                      onClick={() => {
                        if (
                          customerMapping.selected?.mappingDemand?.statusKey ===
                          'FINISHED'
                        ) {
                          return;
                        }
                        dispatch({
                          type: 'SET_SELECTED_CUSTOMER_SECTOR',
                          payload: sector,
                        });
                        navigate(`/tools/mapping/sector/${sector.id}`);
                      }}
                      className={`p-0 pe-1 ps-1 ${
                        customerMapping.selected?.mappingDemand?.statusKey ===
                        'FINISHED'
                          ? 'cursor-not-allowed'
                          : 'cursor-pointer'
                      }`}
                      xs="auto"
                    >
                      <VisibilityOnIcon
                        fill="#fff"
                        width="13px"
                        height="13px"
                        padded={false}
                      />{' '}
                      {sector.sectorName}
                    </Col>
                    <Col
                      className={`p-0 pe-1 ${
                        customerMapping.selected?.mappingDemand?.statusKey ===
                        'FINISHED'
                          ? 'cursor-not-allowed'
                          : 'cursor-pointer'
                      }`}
                      onClick={() => {
                        if (
                          customerMapping.selected?.mappingDemand?.statusKey ===
                          'FINISHED'
                        ) {
                          return;
                        }
                        dispatch({
                          type: 'SET_SELECTED_CUSTOMER_SECTOR',
                          payload: sector,
                        });
                        setShowModalRemoveSector(true);
                      }}
                      xs="auto"
                    >
                      <CloseIcon
                        fill="#fff"
                        width="13px"
                        height="13px"
                        padded={false}
                      />
                    </Col>
                  </Row>
                  <div />
                </RoundedPill>
              ))}
              {customerMapping.selected?.mappingDemand?.statusKey !==
                'FINISHED' && (
                <>
                  <p className="ms-2 mb-0 mt-1">Incluir setores</p>
                  {nonCustomerSectorsToShow.map((sector) => (
                    <RoundedPill key={sector} validatedSelected>
                      <Row
                        className="m-0 cursor-pointer"
                        onClick={() => {
                          insertSector(sector);
                        }}
                      >
                        <Col className="p-0 pe-1 ps-1" md="auto">
                          + {sector}
                        </Col>
                      </Row>
                      <div />
                    </RoundedPill>
                  ))}
                  <RoundedPill validatedSelected>
                    <Row
                      className="m-0 cursor-pointer"
                      onClick={() => setShowModalInsertOtherSector(true)}
                    >
                      <Col className="p-0 pe-1 ps-1" md="auto">
                        + Outros
                      </Col>
                    </Row>
                    <div />
                  </RoundedPill>
                </>
              )}
            </>
          )}
        </CardForContent>
      </MainPage>
      <div className="mt-3" />
      <MainPage>
        <CardForContent
          height=""
          Icon={MappingIcon}
          title="Mapeamentos realizados"
          hasCard={false}
          className="ps-2 pe-2"
        >
          <Table
            className="mt-5"
            columns={columnsDocuments}
            pagination
            paginationServer
            fetchFunction={CustomerMappingDocumentProvider.getAll}
            dispatchString="DOCUMENT_LIST"
            listReducerKey="documentList"
            unStyled
          />
        </CardForContent>
      </MainPage>
      <ModalConfirmation
        close={() => setShowModalRemake(false)}
        open={showModalRemake}
        onConfirmation={reopenMapping}
        title="Você realmente deseja refazer o mapeamento de dados?"
        description="Está ação irá colocar em pendente a demanda de mapeamento antiga, permitindo que você edite seus dados preenchidos e envie para revisão novamente."
      />
      <ModalConfirmation
        close={() => setShowModalReview(false)}
        open={showModalReview}
        onConfirmation={reviewMapping}
        title="Você realmente deseja enviar para revisão?"
        description="Está ação irá remover impedir sua edição até que o advogado resposável libere."
      />
      <ModalConfirmation
        close={() => setShowModalRemoveSector(false)}
        open={showModalRemoveSector}
        onConfirmation={removeSector}
        title="Você realmente deseja excluir esse setor?"
        description="Está ação irá remover todas atividades relacionadas ao mapeamento desse setor."
      />
      <ModalForm
        open={showModalInsertOtherSector}
        close={() => setShowModalInsertOtherSector(false)}
        title="Outros"
        onSubmit={insertOtherSector}
      >
        <InputGeneric label="Nome do setor*" name="sectorName" />
      </ModalForm>
      <ModalConfirmation
        close={() => navigate('/tools')}
        open={
          !authUser?.mappingResponsibilityCheckDate &&
          customerMapping.selected.demandId
        }
        onConfirmation={async () => {
          await userProvider.editUser(authUser.id, {
            mappingResponsibilityCheckDate: new Date(),
          });
          window.location.reload();
        }}
        title="Aviso antes do preenchimento"
        overflowScroll
        description={
          <div className="overflow-scroll h-50">
            <p>
              <strong>1</strong>- A presente ferramenta possui o intuito apenas
              de facilitar o mapeamento dos dados pessoais tratados pelo
              cliente, mas esclarecemos que esta é uma tarefa interna e de
              responsabilidade exclusiva da empresa.
            </p>
            <p>
              <strong>2</strong>- Todas as opções de respostas são apenas
              exemplos para facilitar a compreensão, cabendo apenas a empresa
              avaliá-las e complementá-las, com base na realidade do negócio.
            </p>
            <p>
              <strong>3</strong>- A NDM Advogados procederá com a análise de
              acordo com as informações inseridas pelo cliente e não se
              responsabiliza por informações falsas, incompletas ou
              desatualizadas.
            </p>
            <p>
              <strong>4</strong>- É papel da empresa, após a inserção das
              informações, realizar o download do seu mapeamento e o manter
              armazenado em ambiente restrito e seguro. A NDM Advogados não se
              compromete a fazer a guarda dessas informações e alertamos que a
              empresa poderá precisar deste documento a qualquer momento.
            </p>
            <p>
              <strong>5</strong>- Lembre-se que o mapeamento deve ser feito com
              cautela, da forma mais completa possível e que, a sua ausência ou
              preenchimento incorreto, acarretará uma análise jurídica
              comprometida.
            </p>
            <p>
              <strong>Vamos ao trabalho!</strong>
            </p>
          </div>
        }
      />
    </>
  );
}
