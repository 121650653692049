import React, { useEffect } from 'react';
import InputRadio from './Inputs/Input_Radio';

export default function RadioPfOrPj({ renderFunction, setReduxFunction }) {
  useEffect(() => {
    renderFunction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <InputRadio
      options={[
        {
          value: 'PF',
          label: 'Pessoa Física',
        },
        {
          value: 'PJ',
          label: 'Pessoa Jurídica',
        },
      ]}
      name="personType"
      label="Pessoa Física"
      defaultValue="PF"
      onChange={(e) => {
        setReduxFunction(e.id.split('_')[1]);
      }}
      register={() => {}}
    />
  );
}
