import { toast } from 'react-toastify';
import Api from '.';
import s3 from './urlGenerator';

const demandsProvider = {
  create: async (demand) => {
    try {
      const { data } = await Api.post('/demands', demand);
      return data;
    } catch (error) {
      toast.error('Erro ao criar demanda!');
      return error;
    }
  },
  getDemands: async (params) => {
    const res = await Api.get('/demands', { params });
    return res.data;
  },
  getDemandsMassive: async () => {
    try {
      const { data } = await Api.get('/demands/massive');
      return data;
    } catch (error) {
      toast.error('Erro ao buscar demandas em massa!');
      return error;
    }
  },
  getDemandsMassiveType: async (params) => {
    try {
      const { data } = await Api.get('/demands/massive/type', { params });
      return data;
    } catch (error) {
      toast.error('Erro ao buscar demandas em massa!');
      return error;
    }
  },
  getNextPrioritySla: async (demandId) => {
    const { data } = await Api.get(`/demands/sla/${demandId}`);
    return data;
  },

  screening: async (demandId, params) => {
    try {
      const { data } = await Api.put(`/demands/${demandId}/screening`, params);
      toast.success('Demanda triada com sucesso!');
      return data;
    } catch (error) {
      toast.error('Erro ao triar demanda!');
      return error;
    }
  },
  prioritizeDemand: async (demandId) => {
    return Api.patch(`/demands/${demandId}/prioritize`)
      .then((response) => {
        toast.success('Demanda priorizada com sucesso!');
        return response.data;
      })
      .catch((error) => {
        const errorMessage =
          error.response.data.message || 'Erro ao priorizar demanda!';
        toast.error(errorMessage);
      });
  },
  getAnormalDemands: async (params) => {
    const { data } = await Api.get('/demands/anormal-demands', { params });
    return data;
  },
  async updateDemandStatus(demandId, data) {
    return this.updateDemand(demandId, data)
      .then((response) => {
        toast.success('Status da demanda atualizado com sucesso!');
        return response;
      })
      .catch((error) => {
        toast.error('Erro ao atualizar status da demanda!');
      });
  },
  async createMessage(demandId, data) {
    return Api.post(`/demand-process-item/`, { ...data, demandId })
      .then((response) => response.data)
      .catch((error) => {
        toast.error('Erro ao enviar mensagem!');
        throw error;
      });
  },
  async createMessageWithFiles({
    demandId,
    content,
    attachments,
    messageOptions,
  }) {
    const files = await Promise.all(
      attachments.map(async (file) => {
        return s3.uploadFile(file);
      }),
    );

    return this.createMessage(demandId, {
      content,
      attachments: files,
      ...messageOptions,
    });
  },
  deleteMessage(demandProcessItemId) {
    return Api.delete(`/demand-process-item/${demandProcessItemId}`);
  },
  getDemandById: async (id) => {
    try {
      const [demand, processItemsToDemand] = await Promise.all([
        Api.get(`/demands`, {
          params: {
            where: {
              key: 'id',
              value: id,
            },
          },
        }),
        Api.get(`/demand-process-item`, {
          params: {
            where: {
              key: 'demandId',
              value: id,
            },
            order: [['createdAt', 'ASC']],
            limit: 9999999,
          },
        }),
      ]);

      return {
        ...demand.data.rows[0],
        messageData: { demandProcessItems: processItemsToDemand.data.rows },
      };
    } catch (error) {
      return null;
    }
  },
  async demandTogglePrivate(
    demandId,
    additionalParams = {},
    toastMessage = true,
  ) {
    return Api.patch(`/demands/${demandId}/private`, additionalParams).then(
      (response) => {
        if (toastMessage) {
          toast.success('Demanda atualizada com sucesso!');
        }
        return response.data;
      },
    );
  },
  async createPrivateMessage(demandId, data) {
    return this.createMessage(demandId, {
      ...data,
      messageType: 'PRIVATE_MESSAGE',
    });
  },
  async alterDemandSLA(demandId, data) {
    return this.updateDemand(demandId, data)
      .then((response) => {
        toast.success('Demanda atualizada com sucesso!');
        return response.data;
      })
      .catch((error) => {
        toast.error('Erro ao atualizar demanda!');
      });
  },
  async alterDemandType(demandId, data) {
    return Api.patch(`/demands/${demandId}/type`, data)
      .then((response) => {
        toast.success('Demanda atualizada com sucesso!');
        return response.data;
      })
      .catch((error) => {
        toast.error('Erro ao atualizar demanda!');
      });
  },
  async alterTitleDemand(demandId, data) {
    return Api.patch(`/demands/${demandId}/title`, data)
      .then((response) => {
        toast.success('Demanda atualizada com sucesso!');
        return response.data;
      })
      .catch((error) => {
        toast.error('Erro ao atualizar demanda!');
      });
  },
  async addOrRemoveUserFollowingToDemand(
    demandId,
    additionalParams = {},
    toastMessage = true,
  ) {
    return Api.patch(`/demands/${demandId}/toggle-following`, additionalParams)
      .then((response) => {
        if (toastMessage) {
          toast.success('Demanda atualizada com sucesso!');
        }
        return response.data;
      })
      .catch((error) => {
        if (toastMessage) {
          toast.error('Erro ao atualizar demanda!');
        }
      });
  },
  async updateDemand(id, data) {
    return Api.patch(`/demands/${id}`, data).then((response) => {
      return response.data;
    });
  },
  async deleteDemand(demandId) {
    return Api.delete(`/demands/${demandId}`)
      .then((response) => {
        toast.success('Demanda excluída com sucesso!');
        return response.data;
      })
      .catch((error) => {
        toast.error('Erro ao excluir demanda!');
      });
  },
  async alterBackofficeUser(demandId, data) {
    return Api.patch(`/demands/${demandId}/backoffice-user`, data)
      .then((response) => {
        toast.success('Demanda atualizada com sucesso!');
        return response.data;
      })
      .catch((error) => {
        toast.error('Erro ao atualizar demanda!');
      });
  },
  async generateCustomerDemandSiteUrl(demandId) {
    return Api.get(`/demands/${demandId}/link-customer`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        toast.error('Erro ao gerar link da demanda!');
      });
  },
  async generateBackofficeDemandSiteUrl(demandId) {
    return Api.get(`/demands/${demandId}/link-backoffice`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        toast.error('Erro ao gerar link da demanda!');
      });
  },
  async generateTrimestralReport(params) {
    return Api.post('/demands/trimestral-report', params).then(
      (response) => response.data,
    );
  },
  async sendTrimestralReport(params) {
    return Api.post('/demands/send-trimestral-report', params).then(
      (response) => response.data,
    );
  },
};

export default demandsProvider;
