import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import TitlePage from '../../../components/Pages/TitlePage';
import MainPage from '../../../components/Pages/MainPage';
import {
  addPartnerPFSchema,
  addPartnerPJSchema,
} from '../../../validators/schemas/partners/addPartners';
import PartnersProvider from '../../../providers/partners';
import PartnerForm from './PartnerForm';
import RadioPfOrPj from '../../../components/RadioPfOrPj';
import ModalConfirmation from '../../../components/Modals/ModalConfirmation';
import ButtonGeneric from '../../../components/Button';

function NewPartnerIndex() {
  const partnerData = useSelector((state) => state.partner);
  const dispatch = useDispatch();
  const partner = useSelector(
    ({ partnersList }) => partnersList.selectedPartner,
  );
  const load = useSelector(({ loading }) => loading);
  const modalData = useSelector(({ modal }) => modal);
  const { id } = useParams();
  const navigate = useNavigate();
  const form = useForm({
    resolver: yupResolver(
      partnerData?.personType === 'PF'
        ? addPartnerPFSchema
        : addPartnerPJSchema,
      // schemaGeneric,
    ),
  });

  const createPartnerToForm = async (data) => {
    const request = {
      ...data,
    };
    const response = await PartnersProvider.create(request);
    if (response) {
      toast.success('Parceiro cadastrado com sucesso!');
      navigate('/partners');
    }
  };

  const editPartner = async (data) => {
    const request = {
      ...data,
      id,
    };
    const response = await PartnersProvider.updateById(request);
    if (response) {
      toast.success('Parceiro atualizado com sucesso');
      navigate('/partners');
    }
  };

  const deletePartner = async () => {
    const response = await PartnersProvider.delete(id);
    if (response) {
      toast.success('Parceiro deletado com sucesso');
      toClose();
      navigate('/partners');
    }
  };
  const toClose = () => {
    dispatch({ type: 'CLOSE_MODAL' });
  };

  const fetch = async () => {
    const response = await PartnersProvider.getById(id);
    dispatch({ type: 'SET_SELECTED_PARTNERS', payload: response });
  };
  useEffect(() => {
    if (id) {
      fetch();
    }

    return () => {
      dispatch({ type: 'SET_SELECTED_PARTNERS', payload: {} });
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch({ type: 'SET_SELECTED_PARTNERS', payload: {} });

    // eslint-disable-next-line
  }, [id]);

  const { reset } = form;

  const renderPersonType = (data) => {
    dispatch({
      type: 'SET_PARTNER',
      payload: {
        personType: data,
      },
    });
    reset();
  };
  if (load) return null;

  return (
    <>
      <TitlePage
        title="Parceiros"
        item="Criar Parceiros"
        description="A página exibe o formulário para criar um novo parceiro."
      />
      <MainPage>
        <ModalConfirmation
          close={() => toClose()}
          open={modalData.isOpen}
          onConfirmation={() => deletePartner()}
          title="Deletar Parceiro"
          description="Deseja realmente deletar o parceiro? Essa ação não poderá ser desfeita."
        />

        {id && (
          <Row className="d-flex justify-content-end">
            <Col xs="auto">
              <ButtonGeneric
                type="button"
                variant="danger"
                size="md"
                className="text-white"
                onClick={() => {
                  dispatch({
                    type: 'OPEN_MODAL',
                    payload: {
                      id,
                    },
                  });
                }}
              >
                Deletar Parceiro
              </ButtonGeneric>
            </Col>
          </Row>
        )}
        <RadioPfOrPj
          renderFunction={() => {
            dispatch({
              type: 'RESET_PARTNER',
            });
            renderPersonType('PF');
          }}
          setReduxFunction={renderPersonType}
        />
        <PartnerForm
          type={partnerData}
          submitFunction={id ? editPartner : createPartnerToForm}
          useForm={form}
          buttonText={partner?.email ? 'Atualizar' : 'Cadastrar'}
          creation={!id}
          partner={partner}
        />
      </MainPage>
    </>
  );
}

export default NewPartnerIndex;
