import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { AiOutlineTags, AiOutlineUsergroupAdd } from 'react-icons/ai';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import RadioPfOrPj from '../../../components/RadioPfOrPj';
import TitlePage from '../../../components/Pages/TitlePage';
import MainPage from '../../../components/Pages/MainPage';
import FormGeneric from '../../../components/Form';
import {
  schemaCreateCustomerPF,
  schemaCreateCustomerPJ,
} from '../../../validators/schemas/customers/create';
import ButtonGeneric from '../../../components/Button';
import FormToPF from './formToPF';
import FormToPJ from './formToPJ';
import FormUserToCustomer from './FormUserToCustomer';
import FileInput from '../../../components/Inputs/Input_File';
import ModalAddUser from './ModalAddUser';
import ModalTagsCreateCustomer from './ModalTagsCreateCustomer';
import DEMAND_CONSTANTS from '../../../constants/demands';
import customerProvider from '../../../providers/customer';
import s3 from '../../../providers/urlGenerator';
import FormBasicInfo from './FormBasicInfo';
import FormInfoContact from './FormInfoContact';
import FormAddressData from './FormAddressData';
import InputGeneric from '../../../components/Inputs/Input_generic';
import InputCheckbox from '../../../components/Inputs/Input_Checkbox';
import FormFinance from './FormFinance';
import ModalAddPartner from './ModalAddPartner';
import CustomerFinances from '../../../providers/CustomerFinances';
import PartnersProvider from '../../../providers/partners';

export default function CreateCustomer() {
  const dispatch = useDispatch();
  const customer = useSelector((state) => state.customer);
  const navigate = useNavigate();
  const partner = useSelector((state) => state.partner);

  const form = useForm({
    resolver: yupResolver(
      customer?.personType === 'PF'
        ? schemaCreateCustomerPF
        : schemaCreateCustomerPJ,
      // schemaGeneric,
    ),
  });

  const formatSubmit = (data) => {
    const request = {
      ...data,
      personType: customer.personType,
      contractExpiration: dayjs(data.contractExpiration),
      saleDate: dayjs(data.saleDate),
      birthDate: dayjs(data.birthDate),
      dateOfFirstInstallment: dayjs(data.dateOfFirstInstallment),
      partnerId: customer?.partnerId || null,
      partnerName: partner?.name || null,
      dateOfSinglePayment: dayjs(data.dateOfSinglePayment),
    };

    if (data.isToBePaidAfterService === false) {
      request.priceToBePaid = null;
    }

    request.cep = data['cep/ZIPCODE'];
    delete request['cep/ZIPCODE'];
    if (data?.salesFormFileKey?.length === 0) {
      toast.error('É necessário anexar o formulário de pós venda');
      return false;
    }
    if (data?.serviceContractAwsKey?.length === 0) {
      toast.error('É necessário anexar o formulário de contrato');
      return false;
    }
    const users = [];
    let obj = {};
    let customerAccess = '';
    for (const key in request) {
      if (key.split('_')[1] === 'user') {
        if (obj.id !== key.split('_')[2]) {
          if (Object.keys(obj).length > 0) {
            users.push(obj);
          }
          obj = {};
        }
        obj[key.split('_')[0]] = request[key];
        const id = key.split('_')[2];
        obj.id = id;
        delete request[key];
      } else if (
        key.split('_')[0] === 'customerAccess' &&
        request[key] === true
      ) {
        customerAccess += `${key.split('_')[1]},`;
        delete request[key];
      } else if (request[key] === '') {
        request[key] = null;
      }
    }
    if (Object.keys(obj).length > 0) {
      users.push(obj);
    }
    request.customerAccess = customerAccess || null;
    request.users = users;
    request.tags = customer?.tags || null;
    return request;
  };

  const handleFiles = async (attachments) => {
    return Promise.all(
      attachments?.map(async (file) => {
        const uploaders = await s3.uploadFile(file);
        return uploaders;
      }),
    );
  };

  const handleCreateCustomer = async (data) => {
    const request = formatSubmit(data);
    if (!request) return;
    const fileSale = await handleFiles(data.salesFormFileKey);
    const fileContract = await handleFiles(data.serviceContractAwsKey);
    request.personType = customer?.personType;
    request.salesFormFileKey = fileSale[0]?.awsFileKey;
    request.serviceContractAwsKey = fileContract[0]?.awsFileKey;

    let customerData;

    if (customer?.personType === 'PF') {
      customerData = await customerProvider.create(request);
    } else if (customer?.personType === 'PJ') {
      request.cpf = data.legalRepresentativeCpf;
      customerData = await customerProvider.create(request);
    }
    if (customerData?.id) {
      await createFinance({
        id: customerData.id,
        ...request,
      });
      toast.success('Cliente cadastrado com sucesso!');
      navigate(`/customers/${customerData.id}`);
    }
  };

  const createFinance = async (data) => {
    const body = {
      customerId: data.id,
      partnerId: data.partnerId,
      dateOfFirstInstallment: dayjs(data.dateOfFirstInstallment),
      priceToBePaid: data.priceToBePaid,
      isToBePaidAfterService: data.isToBePaidAfterService,
      instalmentValue: data.instalmentValue,
      numberOfInstallments: data.numberOfInstallments,
      dateOfSinglePayment: data.dateOfSinglePayment,
    };

    await CustomerFinances.create(body);
  };

  const createCustomerToForm = async (data) => {
    await handleCreateCustomer(data);
  };

  const renderFormAddUser = () => {
    dispatch({
      type: 'SET_CUSTOMER',
      payload: {
        showModalAddUser: true,
      },
    });
  };

  const renderModalTags = () => {
    dispatch({
      type: 'SET_CUSTOMER',
      payload: {
        showModalTags: true,
      },
    });
  };

  const renderModalAddPartner = (title) => {
    dispatch({
      type: 'SET_CUSTOMER',
      payload: {
        showModalAddPartner: true,
        modalTitle: title,
      },
    });
  };

  const removeTag = (tag) => {
    const tags = customer.tags || [];
    const newTags = tags.filter((t) => t.id !== tag.id);
    dispatch({
      type: 'SET_CUSTOMER',
      payload: {
        tags: newTags,
      },
    });
  };

  const removePartnerId = () => {
    dispatch({
      type: 'SET_CUSTOMER',
      payload: {
        partnerId: null,
      },
    });
  };

  const resetUserToForm = () => {
    const user = customer?.resetItem;
    const keysToDelete = [
      `name_user_${user.id}`,
      `email_user_${user.id}`,
      `cargo_user_${user.id}`,
      `phone_user_${user.id}`,
      `customerSuperAdmin_user_${user.id}`,
      `customerAdmin_user_${user.id}`,
    ];

    keysToDelete.forEach((key) => {
      form?.unregister(key);
    });
  };

  useEffect(() => {
    if (customer?.resetItem) {
      resetUserToForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer?.resetItem]);

  const { reset } = form;

  const renderPersonType = (data) => {
    dispatch({
      type: 'SET_CUSTOMER',
      payload: {
        personType: data,
        data: null,
        users: [],
        tags: [],
        form,
      },
    });
    reset();
  };

  const fetchPartner = async () => {
    if (customer?.partnerId) {
      const response = await PartnersProvider.getById(customer?.partnerId);
      dispatch({
        type: 'SET_PARTNER',
        payload: response,
      });
    }
  };

  useEffect(() => {
    fetchPartner();

    return () => {
      dispatch({
        type: 'RESET_PARTNER',
      });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer?.partnerId]);

  if (customer?.data?.ActiveContact) {
    return null;
  }
  return (
    <>
      <TitlePage
        title="Cliente"
        item="Criar Cliente"
        description="A página exibe o formulário para criar um novo cliente."
      />
      <MainPage>
        <RadioPfOrPj
          renderFunction={() => {
            dispatch({
              type: 'RESET_CUSTOMER',
            });
            renderPersonType('PF');
          }}
          setReduxFunction={renderPersonType}
        />
        <FormGeneric useForm={form} onSubmit={createCustomerToForm}>
          <FormBasicInfo />
          <Row>
            <Col>
              <p className="fs-14 mb-2 mt-2 ms-1">É indicado por parceiro?</p>
              <InputCheckbox
                label="Indicado por Parceiro?"
                name="isReferral"
                onChange={(e) => {
                  if (e) {
                    renderModalAddPartner('Adicionar Parceiro');
                  } else {
                    removePartnerId();
                  }
                }}
              />
            </Col>
            <Col className="d-flex justify-content-center align-items-end">
              {customer?.partnerId && (
                <div>
                  <ButtonGeneric
                    type="button"
                    variant="secondary"
                    className="border-1 border-dark"
                    icon={AiOutlineUsergroupAdd}
                    size={24}
                    onClick={() => renderModalAddPartner('Editar Parceiro')}
                  >
                    Parceiro: {partner?.name}
                  </ButtonGeneric>
                </div>
              )}
            </Col>
          </Row>
          {customer?.personType === 'PF' ? <FormToPF /> : <FormToPJ />}
          <FormInfoContact />
          <FormAddressData />
          <FormFinance />
          <h5 className="mt-5 pb-2 border-bottom">Documentos Obrigatórios</h5>
          <Row>
            <Col>
              <FileInput
                name="salesFormFileKey"
                label="Registrar Formulário de Venda*"
              />
            </Col>
            <Col>
              <FileInput
                name="serviceContractAwsKey"
                label="Registrar Contrato*"
              />
            </Col>
          </Row>
          <h5 className="mt-5 pb-2 border-bottom">Definir SLA</h5>
          <Row>
            <Col>
              <InputGeneric
                type="number"
                name="numberOfSlas"
                label="Quantidade de SLAs"
                defaultValue="0"
              />
            </Col>
          </Row>
          <Row className="mt-5">
            <Col md={3}>
              <ButtonGeneric
                type="button"
                variant="secondary"
                className="border-1 border-dark"
                icon={AiOutlineUsergroupAdd}
                size={24}
                onClick={renderFormAddUser}
              >
                Adicionar Usuários
              </ButtonGeneric>
            </Col>
            <Col md={3}>
              <ButtonGeneric
                type="button"
                variant="secondary"
                className="border-1 border-dark"
                icon={AiOutlineTags}
                size={24}
                onClick={renderModalTags}
              >
                Adicionar Tags
              </ButtonGeneric>
            </Col>
          </Row>
          <FormUserToCustomer />
          <Row>
            <Col md={6} className="mt-4">
              {customer?.tags?.length > 0 && (
                <>
                  <h5 className="pb-2 border-bottom">Tags para o cliente</h5>
                  {customer?.tags?.map((tag) => (
                    <ButtonGeneric
                      variant={DEMAND_CONSTANTS.TAGS_COLORS[tag.name] || 'dark'}
                      className="text-white"
                      type="button"
                      style={{
                        margin: '0.25rem 0.2rem',
                        fontSize: '12px',
                        width: 'fix-content',
                      }}
                      onClick={() => removeTag(tag)}
                    >
                      {tag?.name}
                    </ButtonGeneric>
                  ))}
                </>
              )}
            </Col>
          </Row>
          <div className="mt-3 d-flex justify-content-center">
            <ButtonGeneric size="lg" className="ms-2 text-white">
              Cadastrar
            </ButtonGeneric>
          </div>{' '}
        </FormGeneric>
        <ModalAddPartner />
        <ModalAddUser />
        <ModalTagsCreateCustomer />
      </MainPage>
    </>
  );
}
