import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';

export default function ContentToPFCustomer() {
  const customer = useSelector((state) => state.customer);

  return (
    <Row className="mb-4">
      <Row className="mb-4">
        <Col>
          <Row className="text-bold">RG:</Row>
          <Row>{customer.rg || 'Indefinido'}</Row>
        </Col>
        <Col>
          <Row className="text-bold">CNH:</Row>
          <Row>{customer.cnh || 'Indefinido'}</Row>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <Row className="text-bold">CPF:</Row>
          <Row>{customer.cpf || 'Indefinido'}</Row>
        </Col>
        <Col>
          <Row className="text-bold">Sexo:</Row>
          <Row>{customer.sex || 'Indefinido'}</Row>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <Row className="text-bold">Nacionalidade:</Row>
          <Row>{customer.nationality || 'Indefinido'}</Row>
        </Col>
        <Col>
          <Row className="text-bold">Estado Civil:</Row>
          <Row>{customer.civilState || 'Indefinido'}</Row>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <Row className="text-bold">Profissão:</Row>
          <Row>{customer.profession || 'Indefinido'}</Row>
        </Col>
        <Col>
          <Row className="text-bold">Data de Nascimento:</Row>
          <Row>{customer.birthDate || 'Indefinido'}</Row>
        </Col>
      </Row>
    </Row>
  );
}
