import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { MdDriveFileRenameOutline } from 'react-icons/md';
import { Col, Form } from 'react-bootstrap';
import TitlePage from '../../../components/Pages/TitlePage';
import MainPage from '../../../components/Pages/MainPage';
import FormGeneric from '../../../components/Form';
import InputGeneric from '../../../components/Inputs/Input_generic';
import ButtonGeneric from '../../../components/Button';
import InputSelect from '../../../components/Inputs/Input_Select';
import FileInput from '../../../components/Inputs/Input_File';
import Table from '../../../components/Table/Table';
import { columnName, dataColumnTable, selects } from './data';
import schemaSendMassiveDemand from '../../../validators/schemas/demands/sendMassiveDemands';
import s3 from '../../../providers/urlGenerator';
import businessProvider from '../../../providers/business';
import TagProvider from '../../../providers/tag';

export default function SendMassiveDemands() {
  const businessesListToCustomer = useSelector((state) => state.customerList);
  const tags = useSelector((state) => state.tagsList.rows);
  const filterBusiness = useSelector((state) => state.filterBusiness);
  const [isReset, setIsReset] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (data) => {
    const attachments = await Promise.all(
      data?.attachments?.map(async (file) => {
        const uploaders = await s3.uploadFile(file);
        return uploaders;
      }),
    );

    const request = {
      ...data,
      attachments,
      businesses: businessesListToCustomer.businesses,
    };

    await businessProvider.sendMassiveDemandToBusiness(request);

    navigate(`/demands`);
  };

  const fetch = async (page = null) => {
    const pageToFetch = page || businessesListToCustomer.page;
    const customersResponse = await businessProvider.getAllActive({
      page: pageToFetch,
      limit: businessesListToCustomer.limit,
      where: businessesListToCustomer.where,
      order: businessesListToCustomer.order,
    });

    const tagsFeteched = await TagProvider.getAll({
      limit: 1000,
    });

    dispatch({
      type: 'SET_CUSTOMER_STATE',
      payload: {
        ...customersResponse,
      },
    });

    dispatch({
      type: 'SET_TAGS_LIST',
      payload: tagsFeteched,
    });
  };

  const handleSort = async (column, sortDirection) => {
    let order = [];
    if (!column.orderArray) {
      order = [[column.columnName, sortDirection]];
    } else {
      order.push([...column.orderArray, sortDirection]);
    }

    if (column.columnName) {
      dispatch({ type: 'SET_CUSTOMER_STATE', payload: { order } });
    }
  };

  const handlePageChange = async (page) => {
    dispatch({ type: 'SET_CUSTOMER_STATE', payload: { page } });
  };

  const handlePerRowsChange = async (limit) => {
    dispatch({ type: 'SET_CUSTOMER_STATE', payload: { limit } });
  };

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    businessesListToCustomer.page,
    businessesListToCustomer.limit,
    businessesListToCustomer.where,
    businessesListToCustomer.order,
  ]);

  useEffect(() => {
    if (isReset) {
      dispatch({ type: 'RESET_FILTER_BUSINESS_STATE' });
      dispatch({ type: 'SET_CUSTOMER_STATE', payload: { where: {} } });
      setIsReset(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReset]);

  useEffect(() => {
    return () => {
      dispatch({ type: 'RESET_CUSTOMER_STATE' });
      dispatch({ type: 'RESET_FILTER_BUSINESS_STATE' });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <TitlePage title="Enviar Demanda em Massa" item="Demandas" />
      <MainPage>
        <FormGeneric onSubmit={handleSubmit} schema={schemaSendMassiveDemand}>
          <InputGeneric
            name="name"
            placeholder="Digite o nome"
            label="Nome*"
            icon={MdDriveFileRenameOutline}
          />
          <InputGeneric
            as="textarea"
            name="description"
            placeholder="Digite a descrição detalhada"
            label="Descrição*"
          />
          <Form.Label className="mt-3">Anexos</Form.Label>
          <FileInput name="attachments" />

          <h5 className="border-top mt-4 mb-3 pt-3">Filtros:</h5>
          <div className="pt-3 d-flex justify-content-end">
            <ButtonGeneric
              className="text-white"
              variant="warning"
              type="button"
              onClick={() => setIsReset(true)}
            >
              Limpar filtro
            </ButtonGeneric>
          </div>
          <Col
            style={{
              marginTop: '1rem',
              display: 'flex',
              gap: '1rem',
            }}
          >
            {selects(businessesListToCustomer?.businesses)?.map(
              (select, index) =>
                index < 3 && (
                  <InputSelect
                    key={select.name}
                    options={select.options}
                    onChange={(e) => {
                      dispatch({
                        type: 'SET_FILTER_BUSINESS_STATE',
                        payload: {
                          [select.name]: select?.options?.find(
                            (option) => option.value === e,
                          ),
                        },
                      });
                      select.onChange(e);
                    }}
                    name={select.name}
                    label={select.label}
                    placeholder={select.placeholder}
                    className="ms-3"
                    value={filterBusiness?.[select.name] || ''}
                  />
                ),
            )}
          </Col>
          <Col
            style={{
              display: 'flex',
              gap: '1rem',
            }}
          >
            {selects(businessesListToCustomer?.businesses, tags)?.map(
              (select, index) =>
                index >= 3 && (
                  <InputSelect
                    key={select.name}
                    options={select.options}
                    onChange={(e) => {
                      let event = e;
                      if (!Array.isArray(e)) {
                        event = [e];
                      }
                      dispatch({
                        type: 'SET_FILTER_BUSINESS_STATE',
                        payload: {
                          [select.name === 'tagId'
                            ? '$Customer.id$'
                            : select.name]: select?.options?.filter(
                            (option) => {
                              return event.includes(option.value);
                            },
                          ),
                        },
                      });
                      select.onChange(event);
                    }}
                    name={select.name}
                    label={select.label}
                    placeholder={select.placeholder}
                    className="ms-3"
                    isMulti={select.name.includes('agId')}
                    value={
                      filterBusiness?.[
                        select.name === 'tagId' ? '$Customer.id$' : select.name
                      ] || ''
                    }
                  />
                ),
            )}
          </Col>
          {businessesListToCustomer?.rows?.length > 0 ? (
            <Table
              className="mt-5"
              data={dataColumnTable(businessesListToCustomer.rows)}
              columns={columnName}
              pagination
              paginationServer
              onSort={handleSort}
              onChangePage={handlePageChange}
              paginationTotalRows={businessesListToCustomer.count}
              paginationDefaultPage={businessesListToCustomer.page}
              onChangeRowsPerPage={handlePerRowsChange}
              itemsPerPage={businessesListToCustomer.limit}
            />
          ) : (
            <h5 className="mt-5 text-center text-info">
              Nenhum cliente encontrado
            </h5>
          )}

          <div className="pt-5 d-flex justify-content-end">
            <ButtonGeneric
              variant="success"
              className="text-white"
              type="submit"
            >
              Enviar Demanda(s) em massa
            </ButtonGeneric>
          </div>
        </FormGeneric>
      </MainPage>
    </>
  );
}
