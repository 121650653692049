import React from 'react';
import { FaRegUser, FaRegFolder } from 'react-icons/fa';
import { PiGearSix } from 'react-icons/pi';
import { BsFileEarmark } from 'react-icons/bs';
import { RxExit } from 'react-icons/rx';

const dummyRedirect = (url) => {
  window.history.pushState(url, url, url);
  window.location.reload();
};

export const optionsForAll = [
  {
    label: 'Termos de Uso',
    icon: <BsFileEarmark color="rgb(88, 96, 108)" size={20} />,
    onClick: () => {
      window.open('https://customer-ndm-qa.netlify.app/terms');
    },
  },
  {
    label: 'Políticas de Privacidade',
    icon: <BsFileEarmark color="rgb(88, 96, 108)" size={20} />,
    onClick: () => {
      window.open('https://customer-ndm-qa.netlify.app/politics');
    },
  },
  {
    label: 'Sair',
    icon: <RxExit color="rgb(88, 96, 108)" size={20} />,
    onClick: () => {
      localStorage.removeItem('@newndm-token');
      window.location.reload();
    },
  },
];

export const optionsUserCustomer = [
  ...[
    {
      label: 'Perfil',
      icon: <FaRegUser color="rgb(88, 96, 108)" size={20} />,
      onClick: () => {
        dummyRedirect('#/profiles?section=user');
      },
    },
    {
      label: 'Informações',
      icon: <FaRegFolder color="rgb(88, 96, 108)" size={20} />,
      onClick: () => {
        dummyRedirect('#/profiles?section=customer');
      },
    },
    {
      label: 'Gerenciar Usuários',
      icon: <PiGearSix color="rgb(88, 96, 108)" size={20} />,
      onClick: () => {
        dummyRedirect('#/profiles?section=settings');
      },
    },
  ],
  ...optionsForAll,
];
