import Api from '.';

const DocumentProvider = {
  getAll: async (params) => {
    const { data } = await Api.get('/document', { params });
    return data;
  },
  getById: async (id) => {
    const { data } = await Api.get(`/document/${id}`);
    return data;
  },
  create: async (tag) => {
    const { data } = await Api.post('/document', {
      ...tag,
      deliveryTime: 2,
    });
    return data;
  },
  update: async (tag) => {
    const { data } = await Api.patch(`/document/${tag.id}`, tag);
    return data;
  },
  delete: async (tag) => {
    const { data } = await Api.delete(`/document/${tag.id}`);
    return data;
  },
  downloadAll: async (params) => {
    const { data } = await Api.get('/document/downloadAll', { params });
    return data;
  },
  ropa: async (params) => {
    const { data } = await Api.get('/document/ropa-route', { params });
    return data;
  },
};

export default DocumentProvider;
